<style type="text/css">
   /* .form-control{
        margin-left: 10px;
    }
*/
</style>
<template>
   <div class="container-xxl flex-grow-1 container-p-y">
       <h4 class="fw-bold mb-4">User - Details</h4>
        <div class="card">
            <div class="card-body">
               <!--  <pre>{{user}}</pre> -->
               <!-- <pre>{{$store.state.AppConfig.loggedin_user.role_name}}</pre> -->
                <VeeForm  ref="userForm" as="div" class="needs-validation" name="user" v-slot="{ errors }">
                  <form class="validate-form mt-2 pt-50" method="POST" action="/">
                    <div class="row g-3">
                        <div class="col-md-6">
                            <div class="mb-3">
                              <label class="form-label" for="formtabs-first-name"><strong>First Name<span class="required">*</span></strong></label>
                              <Field  type="text" 
                                  class="form-control"
                                  name="first_name" 
                                  rules="required"
                                  v-model="user.first_name" 
                                  placeholder="First Name"
                                  label="First Name">
                              </Field>
                              <small class="text-danger">{{ errors.first_name }}</small>
                            </div>

                            <div class="mb-3">
                              <label class="form-label" for="formtabs-last-name"><strong>Last Name<span class="required">*</span></strong></label>
                              <Field  type="text" 
                                  class="form-control"
                                  name="last_name" 
                                  rules="required"
                                  v-model="user.last_name" 
                                  placeholder="Last Name"
                                  label="Last Name">
                              </Field>
                              <small class="text-danger">{{ errors.last_name }}</small>
                            </div>

                            <div class="mb-3">
                              <label class="form-label" for="formtabs-mobile"><strong>Mobile<span class="required">*</span></strong></label>
                              <Field  type="text" 
                                  class="form-control"
                                  name="mobile" 
                                  rules="required"
                                  v-model="user.mobile" 
                                  placeholder="Mobile"
                                  label="Mobile">
                              </Field>
                              <small class="text-danger">{{ errors.mobile }}</small>
                            </div>

                            <div class="mb-3">
                              <label class="form-label" for="formtabs-email"><strong>Email<span class="required">*</span></strong></label>
                              <Field  type="text" 
                                  class="form-control"
                                  name="email" 
                                  rules="required|email"
                                  v-model="user.email" 
                                  placeholder="Email"
                                  label="Email">
                              </Field>
                              <small class="text-danger">{{ errors.email }}</small>
                            </div>

                            <div class="mb-3" v-if="$store.state.AppConfig.loggedin_user.role_name =='superadmin'">
                              <label class="form-label" for="modalEditUserLanguage"><strong>Client<span class="required">*</span></strong></label>
                              <select
                                id="modalEditUserLanguage"
                                name="modalEditUserLanguage"
                                class="select2 form-select form-control"
                                aria-label="Default select example"
                                v-model="user.client"
                              >
                                <option value="">--Select--</option>
                                <option v-if="$store.state.AppConfig.customers.length" v-for="customer in $store.state.AppConfig.customers" :value="customer.CustomerID">{{customer.CompanyName}}</option>
                              </select>
                            </div>

                            <div class="mb-3">
                              <label class="form-label" for="formtabs-address"><strong>Address<span class="required">*</span></strong></label>
                              <Field as="textarea" 
                                  class="form-control"
                                  rows="4"
                                  name="address" 
                                  rules="required"
                                  v-model="user.address1" 
                                  placeholder="Address"
                                  label="Address">
                              </Field>
                              <small class="text-danger">{{ errors.address }}</small>
                            </div>

                            <div class="mb-3">
                              <label class="form-label" for="formtabs-address"><strong>Post Code</strong></label>
                              <Field  type="text" 
                                  class="form-control"
                                  name="address2" 
                                  v-model="user.address2" 
                                  placeholder="Post Code"
                                  label="Post Code">
                              </Field>
                            </div>

                            <div class="mb-3" v-if="$store.state.AppConfig.loggedin_user.role_name !='superadmin'">
                              <label class="form-label"><strong>Role</strong></label>
                              <div class="col">
                                <div class="form-check form-check-inline">
                                  <input
                                    name="collapsible-address-type"
                                    class="form-check-input"
                                    type="radio"
                                    value="admin"
                                    v-model="user.role_name"
                                    id="collapsible-role-admin"
                                    checked=""
                                  />
                                  <label class="form-check-label" for="collapsible-address-type-home"
                                    >Admin</label
                                  >
                                </div>
                                <div class="form-check form-check-inline">
                                  <input
                                    name="collapsible-address-type"
                                    class="form-check-input"
                                    type="radio"
                                    value="user"
                                    v-model="user.role_name"
                                    id="collapsible-role-user"
                                  />
                                  <label class="form-check-label" for="collapsible-address-type-office">
                                    User
                                  </label>
                                </div>
                              </div>
                            </div>

                            <div class="mb-3">
                                <user-photo :user="user" ></user-photo>
                            </div>
                        </div>                   
                    </div>
                    
                    <div class="pt-4">
                        <button type="button" @click="submitForm" class="btn btn-primary me-sm-3 me-1 waves-effect waves-light">Submit</button>
                        <router-link  class="btn btn-label-primary waves-effect" to="/users/list">Cancel</router-link>
                      </div>
                  </form>
                </VeeForm>
              
            </div>
        </div>
    </div>
</template>

<script>
import jQuery from 'jquery'
import moment from 'moment';
import {Form, Field ,useFieldError,useFormErrors,useValidateForm,useField,useForm} from 'vee-validate';
import vSelect from 'vue-select';
import 'vue-select/dist/vue-select.css';
import '../../js/validation';
import UserPhoto from '../../components/UserPhotoUpload.vue';

export default {
    name: 'detail',
    props: ['shared'],
    components:{
        'VeeForm': Form,
        Field,
        'v-select': vSelect,
        'user-photo': UserPhoto,
    },
    data(){
        return{
            user: {
               client: null,
               role_name: 'user'
            },
            userId : this.$route.params.id,
        }   
    },
    created() {
        var self =this;
        if(self.userId && self.userId != 'create'){
            self.getUserDetail();
        }
    },

    computed:{
    },

    methods:{
        async submitForm() {
            const form = this.$refs.userForm;
            const isValid = await form.validate();

            if (isValid.valid) {
                if(this.user.id){
                  this.updateUser();
                }
                else{
                  this.storeUser();
                }
            }
        },

        storeUser(){
          var self = this;
          self.$axios.post('/user', self.user)
            .then(response => {
              if (!response.data.error){                    
                  self.$router.push({ path: '/users/list' });
              }
            })
            .catch(error => {
              console.error(error);
            });
        },

        updateUser(){
          var self = this;
          self.$axios.put('/user', self.user)
            .then(response => {
              if (!response.data.error){                    
                  toastr['success']('User Updated Successfully', 'Success', {
                      closeButton: true,
                      tapToDismiss: false,
                      timeOut: 5000,
                      rtl: false,
                  });
              }
            })
            .catch(error => {
              console.error(error);
            });
        },

        getUserDetail(){
            var self = this;
            self.$axios.get('/user/'+self.userId)
              .then(response => {
                if (!response.data.error){                    
                    self.user = response.data.input_data;
                }
              })
              .catch(error => {
                console.error(error);
              });
        },

        updateLogo(newLogo){
          this.user.logo = newLogo;
        }
    },

    watch:{
        
    },
}
</script>