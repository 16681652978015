import { createApp } from 'vue'
import App from './App.vue'

import router from './router/index'
import axios from 'axios'
import VueAxios from 'vue-axios'
// import "bootstrap"

import store from './store/store';
import filters from './filters/filters';
import './assets/css/style.css';
import './assets/css/divTable.css';
import Spinner from './components/Spinner.vue'

import FeatherIcon from './components/FeatherIcon.vue';
//element plus
import ElementPlus from 'element-plus'

import VueSocketIO from 'vue-3-socket.io'
import PerfectScrollbar from 'vue3-perfect-scrollbar'
import 'vue3-perfect-scrollbar/dist/vue3-perfect-scrollbar.css'
import { v4 as uuidv4 } from "uuid";
import * as Sentry from "@sentry/vue";
import { BrowserTracing } from "@sentry/tracing";
import { CaptureConsole as CaptureConsoleIntegration } from "@sentry/integrations";
import mitt from 'mitt';
const app = createApp(App)

window.templateCustomizer =  new TemplateCustomizer({
    cssPath: assetsPath + 'vendor/css' + (rtlSupport ? '/rtl' : '') + '/',
    themesPath: assetsPath + 'vendor/css' + (rtlSupport ? '/rtl' : '') + '/',
    displayCustomizer: false,
    defaultTextDir: 'ltr',
    defaultMenuCollapsed: false,
    defaultNavbarFixed: true,
    defaultShowDropdownOnHover: true
  });
// Install the store instance as a plugin

app.use(store);
const eventBus = mitt();
app.config.globalProperties.$eventBus = eventBus;
//app.config.globalProperties.Lang = Lang;
app.config.globalProperties.$axios = axios;
app.config.globalProperties.$filters = filters;


axios.defaults.baseURL = '/api/service/v1';

var headers = {
	  'X-Requested-With': 'XMLHttpRequest',
	  'X-CSRF-TOKEN': jQuery('meta[name="csrf-token"]').attr('content'),
	  'Accept': 'Application/json',
}

axios.defaults.headers.common = headers;

//401 redirection
var sessionUnauthorized401Flag = false;

/*Lang.setLocale(localeCode);
// If lang param is set then use as default language  
const urlParams = new URLSearchParams(window.location.search);
const LangParam = urlParams.get('lang');
if (LangParam) 
{
  Lang.setLocale(LangParam);
  localeCode = LangParam;
}

Lang.setFallback('en');*/
app.component('spinner', Spinner)
app.use(VueAxios, axios)
app.use(PerfectScrollbar)
app.use(router)
app.use(ElementPlus)


document.cookie = "firebaseResponseToken=''";

window.addEventListener('beforeunload', (event) => {
  //event.preventDefault();
  // sockets.disconnect();
});
app.mount('#patient-app')


