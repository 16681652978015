<template>
    <div>
        <nav v-if="totalRows > '0'">
            <ul class="pagination pagination-round pagination-sm m-0">
                <li class="page-item first" v-if="currentPage != '1'" @click="gotoFirst">
                    <a class="page-link waves-effect" style="padding-bottom: 4.5px;" href="javascript:void(0);"><i class="ti ti-chevrons-left ti-xs"></i></a>
                </li>
                <li class="page-item prev" v-if="currentPage != '1'" @click="pageDecrement">
                    <a class="page-link waves-effect" style="padding-bottom: 4.5px;" href="javascript:void(0);"><i class="ti ti-chevron-left ti-xs"></i></a>
                </li>
                <li class="page-item cursor-pointer" v-for="page in pages" :key="page.id">  
                    <a href="javascript:void(0);" class="page-link waves-effect" v-bind:class="{'active' : currentPage == page}" @click="pageChanged(page)">{{page}}</a>
                </li>
                <li class="page-item next" v-if="currentPage != last" @click="pageIncrement">
                    <a class="page-link waves-effect" style="padding-bottom: 4.5px;" href="javascript:void(0);"><i class="ti ti-chevron-right ti-xs"></i></a>
                </li>
                <li class="page-item last" v-if="currentPage != last" @click="gotoLast">
                    <a class="page-link waves-effect" style="padding-bottom: 4.5px;" href="javascript:void(0);"><i class="ti ti-chevrons-right ti-xs"></i></a>
                </li>
            </ul>
        </nav>
    </div>
</template>
<script>


export default ({
    name:'pagination',
    props : {
        perPage : {
            required : true
        },
        activePage : {
            required : true
        },
        totalRows : {
            type : Number,
            required : true
        }
    },
    data(){
        return {
            // shared : d2d.store,
            pages : [],
            maxPages : 5,
            leftPages : [],
            first : 1,
            last : 0,
        //    currentPage : 1,
        }
    },
    created(){
        this.currentPage = this.activePage;
        let result = this.paginate(this.totalRows, this.currentPage, this.perPage, this.maxPages);
        this.pages = result.pages;
        this.last = Math.ceil(this.totalRows / this.perPage);
    },
    methods : {
        pageChanged(currentPageChange){
           if(this.currentPage !== currentPageChange){
                this.currentPage = currentPageChange;
                this.$emit("update:modelValue", currentPageChange);
                let result = this.paginate(this.totalRows, this.currentPage, this.perPage, this.maxPages);
                this.pages = result.pages;
                this.last = Math.ceil(this.totalRows / this.perPage);
            }
            
        },
        gotoFirst(){
            this.$emit("update:modelValue",1);
            this.currentPage = 1;
            let result = this.paginate(this.totalRows, this.currentPage, this.perPage, this.maxPages);
            this.pages = result.pages;
            this.last = Math.ceil(this.totalRows / this.perPage);
        },
        pageDecrement(){
            if(this.currentPage !== 1){
                this.currentPage = this.currentPage - 1;
                this.$emit("update:modelValue", this.currentPage - 1);
                let result = this.paginate(this.totalRows, this.currentPage, this.perPage, this.maxPages);
                this.pages = result.pages;
                this.last = Math.ceil(this.totalRows / this.perPage);
            }
        },
        gotoLast(){
            this.currentPage = this.last;
            this.$emit("update:modelValue", this.last);
            this.last = Math.ceil(this.totalRows / this.perPage);
            let result = this.paginate(this.totalRows, this.currentPage, this.perPage, this.maxPages);
            this.pages = result.pages;
                
        },
        pageIncrement(){
            if(this.currentPage !== this.last ){
                this.currentPage = this.currentPage + 1;
                this.$emit("update:modelValue", this.currentPage + 1);
                let result = this.paginate(this.totalRows, this.currentPage, this.perPage, this.maxPages);
                this.pages = result.pages;
                this.last = Math.ceil(this.totalRows / this.perPage);
            }
        },
        paginate(totalItems, currentPage, pageSize, maxPages){
            // calculate total pages
            let totalPages = Math.ceil(totalItems / pageSize);

            // ensure current page isn't out of range
            if (currentPage < 1) {
                currentPage = 1;
            } else if (currentPage > totalPages) {
                currentPage = totalPages;
            }

            let startPage, endPage;
            if (totalPages <= maxPages) {
                // total pages less than max so show all pages
                startPage = 1;
                endPage = totalPages;
            } else {
                // total pages more than max so calculate start and end pages
                let maxPagesBeforeCurrentPage = Math.floor(maxPages / 2);
                let maxPagesAfterCurrentPage = Math.ceil(maxPages / 2) - 1;
                if (currentPage <= maxPagesBeforeCurrentPage) {
                    // current page near the start
                    startPage = 1;
                    endPage = maxPages;
                } else if (currentPage + maxPagesAfterCurrentPage >= totalPages) {
                    // current page near the end
                    startPage = totalPages - maxPages + 1;
                    endPage = totalPages;
                } else {
                    // current page somewhere in the middle
                    startPage = currentPage - maxPagesBeforeCurrentPage;
                    endPage = currentPage + maxPagesAfterCurrentPage;
                }
            }

            // calculate start and end item indexes
            let startIndex = (currentPage - 1) * pageSize;
            let endIndex = Math.min(startIndex + pageSize - 1, totalItems - 1);

            // create an array of pages to ng-repeat in the pager control
            let pages = Array.from(Array((endPage + 1) - startPage).keys()).map(i => startPage + i);
            
                return {
                    totalItems: totalItems,
                    currentPage: currentPage,
                    pageSize: pageSize,
                    totalPages: totalPages,
                    startPage: startPage,
                    endPage: endPage,
                    startIndex: startIndex,
                    endIndex: endIndex,
                    pages: pages
                };
        }
    },
    watch : {
        'perPage' : function(){
            let result = this.paginate(this.totalRows, this.currentPage, this.perPage, this.maxPages);
            this.pages = result.pages;
            this.last = Math.ceil(this.totalRows / this.perPage);
            
        },
        'totalRows':function(newVal,oldVal){
            let result =this.paginate(this.totalRows, this.currentPage, this.perPage, this.maxPages)
            this.pages = result.pages;
            this.last = Math.ceil(this.totalRows / this.perPage);
        }
    }
})
</script>
