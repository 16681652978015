<style type="text/css">
    .required {
        color: #d16e6c;
        font-weight: bold;
        padding-left: 3px;
    }

</style>
<template>
    <!-- <div >
        <div class="content-wrapper">
           <div class="container-xxl flex-grow-1 container-p-y">
                <span v-if="countries.length" v-for="item in countries">{{item.Country}}--</span>
            </div>
        </div>
    </div> -->

    <div >
        <div class="content-wrapper">
           <div class="container-xxl flex-grow-1 container-p-y">
              <!-- <h4 class="fw-bold ">Fleet Size Evolution</h4> -->

              <div class="card mb-4">
                <h5 class="card-header">Policies</h5>
                 <div class="card-body">
                    <a href="javascript::void(0)" @click="downloadZip('policy')">Click here</a> to download policies
                </div>
              </div>

              <div class="card mb-4">
                 <h5 class="card-header">Resources</h5>
                <div class="card-body">
                    <a href="javascript::void(0)"  @click="downloadZip('resource')">Click here</a> to download resources
                </div>
              </div>

               <div class="card mb-4">
                 <h5 class="card-header">Contracts</h5>
                <div class="card-body">
                    <a href="javascript::void(0)"  @click="downloadZip('contract')">Click here</a> to download contracts
                </div>
              </div>

            </div>
        </div>
    </div>

</template>

<script>

export default {
    name: 'Policies',
    components:{
    },
    props: [],
    data(){
        return{
           countries: []
        }   
    },
    created() {
        this.getPolicies();
    },
    mounted() {
         
    },
    setup() {
    },
    computed:{
        
    },

    methods:{

        async downloadZip(type) {

            var self = this;
            try {
                const response = await this.$axios.get('/download/'+type, {
                  responseType: 'blob', 
                });

                const blob = new Blob([response.data], { type: 'application/zip' });
                const link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);
                link.download = type+'.zip';
                link.click();
            } catch (error) {
                console.error('Error downloading file:', error);
            }
        },

        getPolicies(){
            var self = this;
            this.$axios.get('policies').then(function(response){
                if (response.data.error  == false){
                    console.log("countries======", response.data.input_data);
                    self.countries = response.data.input_data;
                }
                
            })
            .catch(function (error) {
              
            });
        }
    },

    watch:{
        
    },
}
</script>
