<style type="text/css">
    .required {
        color: #d16e6c;
        font-weight: bold;
        padding-left: 3px;
    }

</style>
<template>
    <div class="content-wrapper">
       <div class="container-xxl flex-grow-1 container-p-y">
          <!-- <h4 class="fw-bold">Average CO2</h4> -->

          <div class="row justify-content-between mb-2">
              <div class="col-auto">
                <div class="d-flex align-items-center gap-2">
                 <!--  <button type="button" class="btn btn-icon btn-outline-primary waves-effect">
                    <span class="ti ti-arrow-left"></span>
                  </button> -->
                  <h4 class="fw-bold mb-1"> Average CO2</h4>
                </div>
              </div>
              <div class="col-auto gap-2">
                <div class="d-flex align-items-center gap-2">
                  <button type="button" @click="downloadChart" class="btn btn-outline-primary waves-effect">
                    <span class="ti-xs ti ti-download me-1"></span>Download Chart
                  </button>
                  <button type="button" v-if="!$store.state.AppConfig.co2CSVDownloading && !downloading_complete" @click="downloadCsv" class="btn btn-outline-primary waves-effect">
                    <span class="ti-xs ti ti-download me-1"></span>Download CSV
                  </button>

                  <button v-if="$store.state.AppConfig.co2CSVDownloading" class="btn btn-primary" type="button" disabled>
                    <span class="spinner-border me-1" role="status" aria-hidden="true"></span>
                    Downloading...
                  </button>

                  <router-link v-if="downloading_complete" @click="resetDownloadStatus" class="btn btn-primary waves-effect" :to="'/files/'+csv_file_name" target="_blank"><span class="ti-xs ti ti-download me-1"></span>Download Ready</router-link>

                   <router-link  class="btn btn-outline-primary waves-effect" to="/download-history">Download History</router-link>
                </div>
              </div>
          </div>

          <div class="card mb-4">
            <h5 class="card-header border-bottom">Filters</h5>
            <VeeForm  ref="filterForm" as="div" class="needs-validation" name="filter" v-slot="{ errors }">
              <form class="card-body">
                <div class="row g-3">
                  <!-- <pre>{{filter}}</pre> -->
                  <div class="col-md-4" >
                    <label class="form-label" for="countries"><strong>Country</strong></label>
                        <v-select 
                        :options="$store.state.AppConfig.countries"
                        :value="filter.country"
                        v-model="filter.country" 
                        label="Country"
                        :clearable="false"
                        :reduce="option => option.Country" 
                        placeholder="All"
                        name="country"
                        multiple
                        />
                  </div>

                  <div class="col-md-4" >
                    <label class="form-label" for="divisions"><strong>Division</strong></label>
                        <v-select 
                        :options="divisions"
                        :value="filter.division"
                        v-model="filter.division" 
                        label="Division"
                        :clearable="false"
                        :reduce="option => option.Division" 
                        placeholder="All"
                        name="divisions"
                        multiple
                        />
                  </div>

                  <div class="col-md-4" >
                    <label class="form-label" for="makes"><strong>Make</strong></label>
                        <v-select 
                        :options="makes"
                        :value="filter.make"
                        v-model="filter.make" 
                        label="Make"
                        :clearable="false"
                        :reduce="option => option.Make" 
                        placeholder="All"
                        name="makes"
                        multiple
                        />
                  </div>
                  <div class="col-md-4" >
                    <label class="form-label" for="fuel_types"><strong>Fuel Type</strong></label>
                        <v-select 
                        :options="fuel_types"
                        :value="filter.fuel_type"
                        v-model="filter.fuel_type" 
                        label="Fuel Type"
                        :clearable="false"
                        :reduce="option => option['Fuel Type']" 
                        placeholder="All"
                        name="fuel_types"
                        multiple
                        />
                  </div>

                  <div class="col-md-4">
                    <div class="form-password-toggle">
                      <label class="form-label" for="multicol-confirm-password"><strong>Start Date <span class="required">*</span></strong></label>
                      <Datepicker 
                          locale="en" 
                          v-model="filter.start_date" 
                          autoApply
                          :enableTimePicker="false"
                          :monthChangeOnScroll="false"
                          format="dd-MM-yyyy"
                          >
                        </Datepicker>

                        <Field id="config-date" type="hidden" class="form-control" 
                          rules="required"  name="start_date" 
                          v-model="filter.start_date" 
                          label="Start Date">
                        </Field>
                        <small class="text-danger">{{ errors.start_date }}</small>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="form-password-toggle">
                      <label class="form-label" for="multicol-confirm-password"><strong>End Date<span class="required">*</span></strong></label>
                        <Datepicker 
                          locale="en" 
                          v-model="filter.end_date" 
                          autoApply
                          :enableTimePicker="false"
                          :monthChangeOnScroll="false"
                          format="dd-MM-yyyy"
                          >
                        </Datepicker>

                        <Field id="config-date" type="hidden" class="form-control" 
                          rules="required"  name="end_date" 
                          v-model="filter.end_date" 
                          label="End Date">
                        </Field>
                        <small class="text-danger">{{ errors.end_date }}</small>
                    </div>
                  </div>
                </div>

                <div class="pt-4">
                  <button type="button" @click="submit" class="btn btn-primary me-sm-3 me-1">Apply</button>
                  <router-link  class="btn btn-outline-primary waves-effect" to="/dashboard">Cancel</router-link>
                </div>
              </form>
            </VeeForm>
          </div>

          <div class="card mb-4">
                <h5 class="card-header">Graphical View</h5>
                <div class="card-body">
                    <div class="row g-3">
                        <div class="col-md-12" id="averageCO2Chart">
                            <canvas ref="barChart" class="chartjs" data-height="400" height="500"></canvas>
                        </div>
                    </div>
                    <spinner :active="loading" :is-full-page="true"></spinner> 
                </div>
          </div>


        </div>
    </div>
</template>

<script>
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css';
import {Form, Field ,useFieldError,useFormErrors,useValidateForm,useField,useForm} from 'vee-validate';
import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'
import Pagination from '../../components/Pagination';
import { jsPDF } from "jspdf";

export default {
    name: 'co2',
    components:{
      'VeeForm': Form,
      Datepicker,
      Field,
      Pagination,
      'v-select': vSelect
    },
    props: [],
    data(){
        return{
           myChart: "",
           divisions: [],
           makes: [],
           fuel_types: [],
           filter: {
              "country":[],
              "division":[],
              "make":[],
              "fuel_type": [],
              "start_date":"",
              "end_date":"",
           },
           chartData:[],
           barChartVar: null,
           cyanColor: '#00bcd4',
            yAxisMin: 0,
            yAxisMax: 400,
            loading: false,
          downloading_complete: false,
          csv_file_name: "",
          currentPage:1,
          recordsPerPage:10,
          tableData: [],
          totalData: 0,
        }   
    },
    created() {
      var self = this;
      self.getDivisions();
      self.getMakes();
      self.getFuelTypes();

      const currentDate = new Date();
      const startDate = new Date(currentDate);
      startDate.setMonth(currentDate.getMonth() - 12);
      self.filter.start_date = startDate;
      self.filter.end_date = currentDate;

      if(self.filter.start_date && self.filter.end_date){
        self.getGraphData();
        self.getTableData();
      }
    },
    mounted() {
    },
    setup() {
    },
    computed:{
    },

    methods:{
      getDivisions(){
        var self = this;
        self.$axios.get('/divisions')
          .then(response => {
            if (!response.data.error){                    
                self.divisions = response.data.input_data;
            }
          })
          .catch(error => {
            console.error(error);
          });
      },
      getMakes(){
        var self = this;
        self.$axios.get('/makes')
          .then(response => {
            if (!response.data.error){                    
                self.makes = response.data.input_data;
            }
          })
          .catch(error => {
            console.error(error);
          });
      },
      getFuelTypes(){
          var self = this;
          self.$axios.get('/fuel_types')
            .then(response => {
              if (!response.data.error){                    
                  self.fuel_types = response.data.input_data;
              }
            })
            .catch(error => {
              console.error(error);
            });
        },
      async submit(){
        var self = this;
        const form = this.$refs.filterForm;
        const isValid = await form.validate();

        if (isValid.valid) {
          self.getGraphData();
          self.getTableData();
        }
      },
      getGraphData(){
          var self = this;
          self.loading = true;
          self.$axios.post('/average-co2-graph', self.filter)
          .then(response => {
            if (!response.data.error){ 
                self.chartData = response.data.input_data;
                self.loading = false;
               
                const dataValues = this.chartData.map(item => item.data);
                /*self.yAxisMin = Math.min(...dataValues) - ((2 / 100) * Math.min(...dataValues));
                self.yAxisMax = Math.max(...dataValues) + ((2 / 100) * Math.max(...dataValues));*/

                self.updateChart();
            }
          })
          .catch(error => {
            console.error(error);
          });
      },
      updateChart() {
          const canvasElement = this.$refs.barChart;
          if (canvasElement && canvasElement.getContext) {

            const context = canvasElement.getContext('2d');
            context.clearRect(0, 0, canvasElement.width, canvasElement.height);

            const existingChart = Chart.getChart(canvasElement);
            if (existingChart) {
                existingChart.destroy();
            }

            this.barChartVar = new Chart(context, {
              type: 'line',
              data: {
                labels: this.chartData.map(item => item.label),
                datasets: [
                  {
                    data: this.chartData.map(item => item.data),
                    backgroundColor: this.cyanColor,
                    borderColor: '#2B9AFF',
                    tension: 0.5,
                    pointStyle: 'circle',
                    fill: false,
                    pointRadius: 5,
                    pointHoverRadius: 5,
                    pointHoverBorderWidth: 5,
                    pointBorderColor: 'transparent',
                    pointHoverBorderColor: "#4F5D70",
                    pointHoverBackgroundColor: "#2B9AFF"
                  }
                ]
              },
              options: {
                responsive: true,
                maintainAspectRatio: false,
                animation: {
                  duration: 500
                },
                plugins: {
                  tooltip: {
                    rtl: false,
                    backgroundColor: "#2f3349",
                    titleColor: "#cfd3ec",
                    bodyColor: "#a5a3ae",
                    borderWidth: 1,
                    borderColor: "#a5a3ae"
                  },
                  legend: {
                    display: false
                  }
                },
                scales: {
                  x: {
                    grid: {
                      color: "#dbdade",
                      drawBorder: false,
                      borderColor: "#434968",
                    },
                    ticks: {
                      color: "#a5a3ae",
                    }
                  },
                  y: {
                    min: this.yAxisMin,
                    max: this.yAxisMax,
                    grid: {
                      color:"#dbdade",
                      drawBorder: false,
                      borderColor: "#2f3349",
                    },
                    ticks: {
                      stepSize: (this.yAxisMax - this.yAxisMin) / 5,
                      color: "#a5a3ae",
                      callback: function (value, index, values) {
                        return Math.round(value);
                      },
                    },
                    title: {
                        display: true,
                        text: 'CO2 in g/km',
                    },
                  }
                }
              }
            });
          }
      },

      downloadCsv() {

        var self = this;
        self.$store.commit('SET_CO2_CSV_DOWNLOADING', true);
        self.$axios.post('/download-csv/co2', self.filter)
        .then(response => {
          if (!response.data.error){ 
              self.csv_file_name = response.data.input_data.csv_file_name;
        	  self.$store.commit('SET_CO2_CSV_DOWNLOADING', false);
              self.downloading_complete = true;
          }
        })
        .catch(error => {
          console.error(error);
        });
        /*const queryParams = Object.keys(self.filter)
              .map(key => {
                  if (key === 'start_date' || key === 'end_date') {
                      return encodeURIComponent(key) + '=' + encodeURIComponent(new Date(self.filter[key]).toISOString());
                  } else {
                      return encodeURIComponent(key) + '=' + encodeURIComponent(self.filter[key]);
                  }
              })
              .join('&');

        const apiUrl = '/download-csv/co2?' + queryParams;

        self.$axios.get(apiUrl)
        .then(response => {
            self.downloading_complete = true;
            self.csv_file_name = response.data.input_data.csv_file_name;

            self.$store.commit('SET_CO2_CSV_DOWNLOADING', false);
        })
        .catch(error => {
            console.error('Error downloading CSV', error);
        });*/
      },

      resetDownloadStatus() {
          this.downloading_complete = false;
      },

      getTableData(){
          var self = this;
          self.loading = true;

          self.filter.per_page = self.recordsPerPage;
          self.$axios.post('/co2-data/'+ self.currentPage, self.filter)
          .then(response => {
            if (!response.data.error){ 
                self.tableData = response.data.input_data.data;
                self.totalData = response.data.input_data.total;
                self.loading = false;
            }
          })
          .catch(error => {
            console.error(error);
          });
      },

      loadFirstPage(){
          var self = this;
          clearTimeout(self.debounceTimeout); // Clear the previous timeout
          self.debounceTimeout = setTimeout(() => {
            self.currentPage = null;
            self.currentPage = 1;
            self.getTableData();
          }, 500);
        },
        downloadChart() {
        	// get size of averageCO2Chart page
        	let averageCO2ChartHeight = jQuery('#averageCO2Chart').innerHeight();
        	let averageCO2ChartWidth = jQuery('#averageCO2Chart').innerWidth();
        	// create a new canvas object that we will populate with all other canvas objects
        	let pdfCanvas = $('<canvas />').attr({
        		id: "canvaspdf",
        		width: averageCO2ChartWidth,
        		height: averageCO2ChartHeight
        	});
        	// keep track canvas position
        	let pdfctx = jQuery(pdfCanvas)[0].getContext('2d');
        	let pdfctxX = 0;
        	let pdfctxY = 0;
        	let buffer = 100;
        	// for each chart.js chart
        	jQuery("canvas").each(function(index) {
        		// get the chart height/width
        		let canvasHeight = jQuery(this).innerHeight();
        		let canvasWidth = jQuery(this).innerWidth();
        		// draw the chart into the new canvas
        		pdfctx.drawImage(jQuery(this)[0], pdfctxX, pdfctxY, canvasWidth, canvasHeight);
        		pdfctxX += canvasWidth + buffer;
        		// our report page is in a grid pattern so replicate that in the new canvas
        		if (index % 2 === 1) {
        			pdfctxX = 0;
        			pdfctxY += canvasHeight + buffer;
        		}
        	});
        	// create new pdf and add our new canvas as an image
        	let pdf = new jsPDF('l', 'pt', [averageCO2ChartWidth, averageCO2ChartHeight]);
        	pdf.addImage(jQuery(pdfCanvas)[0], 'PNG', 0, 0);
        	// download the pdf
        	pdf.save('averageCO2Chart.pdf');
          },
        
    },

    watch:{
      'recordsPerPage'(newVal, oldVal){
        var self = this
        if(oldVal && newVal && newVal != oldVal){
          this.loadFirstPage();
        }
      },
      "currentPage"(newVal, oldVal){
        if(oldVal && newVal && newVal != oldVal){
          this.currentPage = newVal;
          this.getTableData();
        }
      },
    },
}
</script>
