<style type="text/css">
    

</style>
<template>
     <div class="container-xxl">
      <div class="authentication-wrapper authentication-basic container-p-y">
        <div class="authentication-inner py-4">
          <!-- Forgot Password -->
          <div class="card">
            <div class="card-body">
              <div class="app-brand justify-content-center mb-4 mt-2">
                    <a href="index.html" class="app-brand-link gap-2">
                      <span class="app-brand-logo demo">
                        <img src="/images/fleetworx.jpg" style="max-width:180px; max-height:180px;">
                      </span>
                    </a>
                  </div>
              <h4 class="mb-1 pt-2">Forgot Password? 🔒</h4>
              <p class="mb-4">Enter your email and we'll send you instructions to reset your password</p>
              <form id="formAuthentication" class="mb-3" action="/reset-password" method="POST">
                <div class="mb-3">
                  <label for="email" class="form-label">Email</label>
                  <input
                    type="text"
                    class="form-control"
                    id="email"
                    name="email"
                    placeholder="Enter your email"
                    autofocus
                  />
                </div>
                <button class="btn btn-primary d-grid w-100">Send Reset Link</button>
              </form>
              <div class="text-center">
                <router-link  class="d-flex align-items-center justify-content-center" to="/login"><i class="ti ti-chevron-left scaleX-n1-rtl"></i>Back to login</router-link>
                <!-- <a href="/login" class="d-flex align-items-center justify-content-center">
                  <i class="ti ti-chevron-left scaleX-n1-rtl"></i>
                  Back to login
                </a> -->
              </div>
            </div>
          </div>
          <!-- /Forgot Password -->
        </div>
      </div>
    </div>
</template>

<script>

import '../../../../public/v3-assets/vendor/css/pages/page-auth.css';



export default {
    name: 'dashboard',
    components:{
    },
    props: [],
    data(){
        return{
           
        }   
    },

    created() {
    },
     setup() {
   
  },

    computed:{
        
    },

    methods:{

        
    },

    watch:{
        
    },
}
</script>
