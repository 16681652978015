const mutations = {
  // /////////////////////////////////////////////
  // User/Account
  // /////////////////////////////////////////////

  // Updates user info in state and localstorage
  UPDATE_SITE_CONFIG(state, payload) {
    // Get Data localStorage
    let config = JSON.parse(localStorage.getItem("config")) || state.AppConfig

    for (const property of Object.keys(payload)) {

      if (payload[property] != null) {
        // If some of user property is null - user default property defined in state.AppConfig
        state.AppConfig[property] = payload[property]

        // Update key in localStorage
        config[property] = payload[property]
      }
    }
    // Store data in localStorage
    localStorage.setItem("config", JSON.stringify(config))
  },
  UPDATE_USER(state, payload) {
    let config = JSON.parse(localStorage.getItem("config")) || state.AppConfig    
    state.AppConfig['loggedin_user'][ payload['attribute'] ] = payload['value'];
    config['loggedin_user'][ payload['attribute'] ] = payload['value'];
    localStorage.setItem("config", JSON.stringify(config))
  },
  COUNTRIES(state, payload) {
    state.AppConfig['countries'] = payload;
  },
  SUPPLIERS(state, payload) {
    state.AppConfig['suppliers'] = payload;
  },
  CUSTOMERS(state, payload) {
    state.AppConfig['customers'] = payload;
  },
  SET_COST_CSV_DOWNLOADING(state, payload) {
    state.AppConfig['costCSVDownloading'] = payload;
  },
  SET_FLEETSIZE_CSV_DOWNLOADING(state, payload) {
    state.AppConfig['fleetSizeCSVDownloading'] = payload;
  },
  SET_VEHICLEORDER_CSV_DOWNLOADING(state, payload) {
    state.AppConfig['vehicleOrderCSVDownloading'] = payload;
  },
  SET_CO2_CSV_DOWNLOADING(state, payload) {
    state.AppConfig['co2CSVDownloading'] = payload;
  },
  SET_HISTORY_CSV_DOWNLOADING(state, payload) {
    state.AppConfig['historyCSVDownloading'] = payload;
  },
  SET_INVOICE_CSV_DOWNLOADING(state, payload) {
    state.AppConfig['invoiceCSVDownloading'] = payload;
  },
}

export default mutations

