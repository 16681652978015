<style type="text/css">
    .required {
        color: #d16e6c;
        font-weight: bold;
        padding-left: 3px;
    }

    .scrollable-table {
      /*max-height: 300px;*/
      overflow-y: auto;
    }

    th, td {
      white-space: nowrap;
    }

    .text-center{
      text-align: center;
    }

</style>
<template>
    <div class="content-wrapper">
       <div class="container-xxl flex-grow-1 container-p-y">

           <div class="row justify-content-between mb-2">
              <div class="col-auto">
                <div class="d-flex align-items-center gap-2">
                  <h4 class="fw-bold mb-1"> Vehicle / Employee History</h4>
                </div>
              </div>
              <div class="col-auto gap-2">
                <div class="d-flex align-items-center gap-2">
                  <button v-if="filter_by == 'vehicle' && !downloading_complete" type="button" :disabled="vehicleData && Object.keys(vehicleData).length == 0" @click="downloadCsv" class="btn btn-outline-primary waves-effect">
                     <span class="ti-xs ti ti-download me-1"></span>Download CSV
                  </button>
                  
                  <button v-if="filter_by == 'employee' && !downloading_complete" type="button" :disabled="employeeData && Object.keys(employeeData).length == 0" @click="downloadCsv" class="btn btn-outline-primary waves-effect">
                    <span class="ti-xs ti ti-download me-1"></span>Download CSV
                  </button>

                  <router-link v-if="downloading_complete" @click="resetDownloadStatus" class="btn btn-primary waves-effect" :to="'/files/'+csv_file_name" target="_blank"><span class="ti-xs ti ti-download me-1"></span>Download Ready</router-link>
                  
                   <router-link  class="btn btn-outline-primary waves-effect" to="/download-history">Download History</router-link>
                </div>
              </div>
          </div>

          <!-- <div class="row justify-content-between mb-2">
              <div class="col-auto">
                  <h4 class="fw-bold mb-1">Vehicle / Employee History</h4>
              </div>
              <div class="col-auto" >
                  <button v-if="filter_by == 'vehicle'" type="button" :disabled="vehicleData && Object.keys(vehicleData).length == 0" @click="downloadCsv" class="btn btn-outline-primary waves-effect">
                     <span class="ti-xs ti ti-download me-1"></span>Download CSV
                  </button>
                  
                  <button v-if="filter_by == 'employee'" type="button" :disabled="employeeData && Object.keys(employeeData).length == 0" @click="downloadCsv" class="btn btn-outline-primary waves-effect">
                    <span class="ti-xs ti ti-download me-1"></span>Download CSV
                  </button>
              </div>
          </div> -->

          <div class="card mb-4">
           <!--  <h5 class="card-header border-bottom">Filters</h5> -->
            <VeeForm  ref="filterForm" as="div" class="needs-validation" name="filter" v-slot="{ errors }">
              <form class="card-body">
                <div class="row g-3">
                  <!-- <pre>{{employeeData}}</pre>
                  <pre>{{vehicleData}}</pre> -->

                  <div class="col-md-12">
                    <label class="form-label" for="vehicle"><strong>Search By <span class="required">*</span></strong></label><br>
                    <div class="form-check form-check-inline">
                      <input name="collapsible-address-type" class="form-check-input" type="radio" value="vehicle" id="collapsible-address-type-home" v-model="filter_by" checked="">
                      <label class="form-check-label" for="collapsible-address-type-home">Vehicle Reg. Number</label>
                    </div>

                    <div class="form-check form-check-inline">
                      <input name="collapsible-address-type" class="form-check-input" type="radio" value="employee" v-model="filter_by" id="collapsible-address-type-office">
                      <label class="form-check-label" for="collapsible-address-type-office">
                        Employee Id
                      </label>
                    </div>

                  </div>

                  <div class="col-md-3" v-if="filter_by == 'vehicle'">
                    <label class="form-label" for="vehicle"><strong>Vehicle Reg. Number <span class="required">*</span></strong></label>
                      <Field 
                          id="vehicle" 
                          type="text" 
                          class="form-control" 
                          rules="required"  
                          name="reg_number" 
                          v-model="filter.reg_number" 
                          label="Reg. Number">
                      </Field>
                      <small class="text-danger">{{ errors.reg_number }}</small>
                  </div>

                  <div class="col-md-3" v-if="filter_by == 'employee'">
                    <label class="form-label" for="employee_id"><strong>Employee Id <span class="required">*</span></strong></label>
                    <div class="input-group">
                        <span class="input-group-text" id="basic-addon11" v-if="$store.state.AppConfig.client_prefix">{{$store.state.AppConfig.client_prefix}}</span>
                        <!-- <input
                          type="text"
                          class="form-control"
                          placeholder="Username"
                          aria-label="Username"
                          aria-describedby="basic-addon11"
                        /> -->
                        <Field 
                          id="employee_id" 
                          type="text" 
                          class="form-control" 
                          rules="required"  
                          name="employee_id" 
                          v-model="filter.employee_id" 
                          label="Employee Id">
                      </Field>
                      </div>
                      
                      <small class="text-danger">{{ errors.employee_id }}</small>
                  </div>

                </div>

                <div class="pt-4">
                  <button type="button" @click="submit" class="btn btn-primary me-sm-3 me-1">Apply</button>
                  <router-link  class="btn btn-label-primary waves-effect" to="/dashboard">Cancel</router-link>
                </div>
              </form>
            </VeeForm>
          </div>

          <div class="col-xl-12" v-if="employeeData && Object.keys(employeeData).length > 0">
            <div class="nav-align-top nav-tabs-shadow mb-4">
              <ul class="nav nav-pills mb-3" role="tablist">
                <li class="nav-item">
                  <button
                    type="button"
                    class="nav-link active"
                    role="tab"
                    data-bs-toggle="tab"
                    data-bs-target="#navs-justified-employee"
                    aria-controls="navs-justified-employee"
                    aria-selected="false"
                  >
                    <i class="tf-icons ti ti-user ti-xs me-1"></i> Employee Details
                  </button>
                </li>
                <li class="nav-item">
                  <button
                    type="button"
                    class="nav-link"
                    role="tab"
                    data-bs-toggle="tab"
                    data-bs-target="#navs-justified-driven-vehicles"
                    aria-controls="navs-justified-driven-vehicles"
                    aria-selected="false"
                  >
                    <i class="tf-icons ti ti-car ti-xs me-1"></i> Driven Vehicles
                  </button>
                </li>
               
              </ul>
              <div class="tab-content">

                <div class="tab-pane fade show active" id="navs-justified-employee" role="tabpanel">

                  <div class="row g-3">
                    <div class="col-md-6" >
                      <label class="fw-bold mx-2"><strong>First Name: </strong></label>
                      <span>{{employeeData.FirstName}}</span>
                    </div>

                    <div class="col-md-6" >
                      <label class="fw-bold mx-2"><strong>CarAllowance: </strong></label>
                      <span>{{employeeData.CarAllowance}}</span>
                    </div>

                    <div class="col-md-6" >
                      <label class="fw-bold mx-2"><strong>Last Name: </strong></label>
                      <span>{{employeeData.LastName}}</span>
                    </div>

                    <div class="col-md-6" >
                      <label class="fw-bold mx-2"><strong>Fuel_Reimburser: </strong></label>
                      <span>{{employeeData.Fuel_Reimburser}}</span>
                    </div>

                    <div class="col-md-6" >
                      <label class="fw-bold mx-2"><strong>InactiveDate: </strong></label>
                      <span>{{$filters.dateFormat(employeeData.InactiveDate)}}</span>
                    </div>

                    <div class="col-md-6" >
                      <label class="fw-bold mx-2"><strong>FuelCardEligible: </strong></label>
                      <span>{{employeeData.FuelCardEligible}}</span>
                    </div>
                  </div>

                </div>

                <div class="tab-pane fade" id="navs-justified-driven-vehicles" role="tabpanel">
                  <div class="row g-3">
                    <div class="col-md-6" >
                      <label class="fw-bold mx-2"><strong>Division: </strong></label>
                      <span>{{employeeData.Division}}</span>
                    </div>

                    <div class="col-md-6" >
                      <label class="fw-bold mx-2"><strong>Cost Centre: </strong></label>
                      <span>{{employeeData.CostCentre}}</span>
                    </div>

                    <div class="col-md-6" >
                      <label class="fw-bold mx-2"><strong>Car Grade: </strong></label>
                      <span>{{employeeData.CarGrade}}</span>
                    </div>
                  </div>

                  <hr class="my-3">
                  <h6 class="mb-3"><i class="tf-icons ti ti-list ti-xs me-1"></i> Allocated Vehicles</h6>
                  
                  <div class="table-responsive scrollable-table">
                      <table class="table w-100">
                        <thead>
                          <tr>
                            <th><strong>Vehicle Reg. Number</strong></th>
                            <th><strong>Allocation Start</strong></th>
                            <th><strong>Allocation End</strong></th>
                            <th><strong>Action</strong></th>
                          </tr>
                        </thead>
                        <tbody class="table-border-bottom-0">
                          <tr v-for="contract in employeeContractDetail" v-if="employeeContractDetail.length>0">
                            <td>{{contract.RegNumber}}</td>
                            <td>{{$filters.dateFormat(contract.AllocationStart)}}</td>
                            <td>{{$filters.dateFormat(contract.AllocationEnd)}}</td>
                            <td> <router-link target="_blank" class="waves-effect" :to="{ name: 'report', params: { type: 'vehicle-employee-history' }, query: { filter_by: 'vehicle', reg_number: contract.RegNumber } }">View details</router-link></td>
                          </tr>
                          <tr v-else>
                              <td colspan="8" class="text-center">No record found.</td>
                          </tr>
                        </tbody>
                      </table>
                   </div>
                </div>
              </div>
            </div>
          </div>
          <div class="card" v-if="isEmptyEmployee">
              <div class="card-body">
                  <div class="row g-3">
                      <div >No record found.</div>
                  </div>
              </div>
          </div> 

          <div class="col-xl-12" v-if="vehicleData && Object.keys(vehicleData).length > 0">
            <div class="nav-align-top nav-tabs-shadow mb-4">
              <ul class="nav nav-pills mb-3" role="tablist">
                <li class="nav-item">
                  <button
                    type="button"
                    class="nav-link active"
                    role="tab"
                    data-bs-toggle="tab"
                    data-bs-target="#navs-justified-vehicle"
                    aria-controls="navs-justified-vehicle"
                    aria-selected="true"
                  >
                    <i class="tf-icons ti ti-car ti-xs me-1"></i> Vehicle Details
                  </button>
                </li>
                <li class="nav-item">
                  <button
                    type="button"
                    class="nav-link"
                    role="tab"
                    data-bs-toggle="tab"
                    data-bs-target="#navs-justified-contract"
                    aria-controls="navs-justified-contract"
                    aria-selected="false"
                  >
                    <i class="tf-icons ti ti-message ti-xs me-1"></i> Contract Details
                  </button>
                </li>
                <li class="nav-item">
                  <button
                    type="button"
                    class="nav-link"
                    role="tab"
                    data-bs-toggle="tab"
                    data-bs-target="#navs-justified-who"
                    aria-controls="navs-justified-who"
                    aria-selected="false"
                  >
                    <i class="tf-icons ti ti-user ti-xs me-1"></i> Who and Where
                  </button>
                </li>
                <li class="nav-item">
                  <button
                    type="button"
                    class="nav-link"
                    role="tab"
                    data-bs-toggle="tab"
                    data-bs-target="#navs-justified-history"
                    aria-controls="navs-justified-history"
                    aria-selected="false"
                  >
                    <i class="tf-icons ti ti-history ti-xs me-1"></i> Spend History
                  </button>
                </li>
              </ul>
              <div class="tab-content">

                <div class="tab-pane fade show active" id="navs-justified-vehicle" role="tabpanel">

                  <div class="row g-3">
                    <div class="col-md-6" >
                      <label class="fw-bold mx-2"><strong>Make: </strong></label>
                      <span>{{vehicleData.Make}}</span>
                    </div>

                    <div class="col-md-6" >
                      <label class="fw-bold mx-2"><strong>Model: </strong></label>
                      <span>{{vehicleData.Model}}</span>
                    </div>

                    <div class="col-md-6" >
                      <label class="fw-bold mx-2"><strong>Description: </strong></label>
                      <span>{{vehicleData.Description}}</span>
                    </div>

                    <div class="col-md-6" >
                      <label class="fw-bold mx-2"><strong>Fuel: </strong></label>
                      <span>{{vehicleData.Fuel}}</span>
                    </div>

                    <div class="col-md-6" >
                      <label class="fw-bold mx-2"><strong>Financial Rental: </strong></label>
                      <span>{{$filters.numberFormat(vehicleData.FinanceRentalBudget)}}</span>
                    </div>

                    <div class="col-md-6" >
                      <label class="fw-bold mx-2"><strong>Service Rental: </strong></label>
                      <span>{{$filters.numberFormat(vehicleData.ServiceRentalBudget)}}</span>
                    </div>

                    <div class="col-md-6" >
                      <label class="fw-bold mx-2"><strong>Tyres: </strong></label>
                      <span>{{$filters.numberFormat(vehicleData.Tyres)}}</span>
                    </div>

                    <div class="col-md-6" >
                      <label class="fw-bold mx-2"><strong>3rd Party: </strong></label>
                      <span>{{$filters.numberFormat(vehicleData['3rdPartyInsurance'])}}</span>
                    </div>

                    <div class="col-md-6" >
                      <label class="fw-bold mx-2"><strong>Comp Insurance: </strong></label>
                      <span>{{$filters.numberFormat(vehicleData.CompInsurance)}}</span>
                    </div>

                    <div class="col-md-6" >
                      <label class="fw-bold mx-2"><strong>Repl Veh: </strong></label>
                      <span>{{$filters.numberFormat(vehicleData.ReplacementVehicle)}}</span>
                    </div>

                    <div class="col-md-6" >
                      <label class="fw-bold mx-2"><strong>Fuel: </strong></label>
                      <span>{{$filters.numberFormat(vehicleData.Fuel)}}</span>
                    </div>

                    <div class="col-md-6" >
                      <label class="fw-bold mx-2"><strong>Road Tax: </strong></label>
                      <span>{{$filters.numberFormat(vehicleData.RoadTax)}}</span>
                    </div>

                    <div class="col-md-6" >
                      <label class="fw-bold mx-2"><strong>Radio Tax: </strong></label>
                      <span>{{$filters.numberFormat(vehicleData.RadioTax)}}</span>
                    </div>

                    <div class="col-md-6" >
                      <label class="fw-bold mx-2"><strong>Total Cost: </strong></label>
                      <span>{{$filters.numberFormat(vehicleData.TotalCost)}}</span>
                    </div>

                    <div class="col-md-6" >
                      <label class="fw-bold mx-2"><strong>Vehicle Type: </strong></label>
                      <span>{{vehicleData['Vehicle Type']}}</span>
                    </div>

                    <div class="col-md-6" >
                      <label class="fw-bold mx-2"><strong>P11D Value: </strong></label>
                      <span>{{$filters.numberFormat(vehicleData.P11DValue)}}</span>
                    </div>

                    <div class="col-md-6" >
                      <label class="fw-bold mx-2"><strong>Combined MPG: </strong></label>
                      <span>{{vehicleData.CombinedMPG}}</span>
                    </div>

                    <div class="col-md-6" >
                      <label class="fw-bold mx-2"><strong>CO2: </strong></label>
                      <span>{{vehicleData.CO2Emissions}}</span>
                    </div>

                    <div class="col-md-6" >
                      <label class="fw-bold mx-2"><strong>Taxable CO2: </strong></label>
                      <span>{{vehicleData.TaxableCO2}}</span>
                    </div>
                  </div>

                </div>

                <div class="tab-pane fade" id="navs-justified-contract" role="tabpanel">
                  <div class="row g-3">
                    <div class="col-md-6" >
                      <label class="fw-bold mx-2"><strong>Supplier Name: </strong></label>
                      <span>{{vehicleData['Supplier Code']}}</span>
                    </div>

                    <div class="col-md-6" >
                      <label class="fw-bold mx-2"><strong>Date of Registration: </strong></label>
                      <span>{{$filters.dateFormat(vehicleData.DateofReg)}}</span>
                    </div>

                    <div class="col-md-6" >
                      <label class="fw-bold mx-2"><strong>Scheme Type: </strong></label>
                      <span>{{vehicleData.SchemeType}}</span>
                    </div>

                    <div class="col-md-6" >
                      <label class="fw-bold mx-2"><strong>Contract Start Date: </strong></label>
                      <span>{{$filters.dateFormat(vehicleData.ContractStart)}}</span>
                    </div>

                    <div class="col-md-6" >
                      <label class="fw-bold mx-2"><strong>Contract Period: </strong></label>
                      <span>{{vehicleData.ContractPeriod}}</span>
                    </div>

                    <div class="col-md-6" >
                      <label class="fw-bold mx-2"><strong>Contract Due End: </strong></label>
                      <span>{{$filters.dateFormat(vehicleData.ContractEnd)}}</span>
                    </div>

                    <div class="col-md-6" >
                      <label class="fw-bold mx-2"><strong>Contract Miles: </strong></label>
                      <span>{{vehicleData.ContractMiles}}</span>
                    </div>

                    <div class="col-md-6" >
                      <label class="fw-bold mx-2"><strong>Off Hire Date: </strong></label>
                      <span>{{$filters.dateFormat(vehicleData.OffHire)}}</span>
                    </div>

                    <div class="col-md-6" >
                      <label class="fw-bold mx-2"><strong>Current Mileage: </strong></label>
                      <span>{{vehicleData.CurrentMileage}}</span>
                    </div>

                    <div class="col-md-6" >
                      <label class="fw-bold mx-2"><strong>Miles Date: </strong></label>
                      <span>{{$filters.dateFormat(vehicleData.MilesDate)}}</span>
                    </div>

                   
                  </div>
                </div>

                <div class="tab-pane fade" id="navs-justified-who" role="tabpanel">
                  <div class="row g-3">
                    <div class="col-md-6" >
                      <label class="fw-bold mx-2"><strong>Division: </strong></label>
                      <span>{{vehicleData.Division}}</span>
                    </div>

                    <div class="col-md-6" >
                      <label class="fw-bold mx-2"><strong>Depot: </strong></label>
                      <span>{{vehicleData.Depot}}</span>
                    </div>

                    <div class="col-md-6" >
                      <label class="fw-bold mx-2"><strong>Department: </strong></label>
                      <span>{{vehicleData.Department}}</span>
                    </div>

                    <div class="col-md-6" >
                      <label class="fw-bold mx-2"><strong>Cost Centre: </strong></label>
                      <span>{{vehicleData.CostCentre}}</span>
                    </div>

                    <div class="col-md-6" >
                      <label class="fw-bold mx-2"><strong>Car Grade: </strong></label>
                      <span>{{vehicleData['Car Grade']}}</span>
                    </div>
                  </div>

                  <hr class="my-3">
                  <h6 class="mb-3"><i class="tf-icons ti ti-users ti-xs me-1"></i> Allocated Drivers</h6>
                  
                  <div class="table-responsive scrollable-table">
                      <table class="table w-100">
                        <thead>
                          <tr>
                            <th><strong>Allocation Start</strong></th>
                            <th><strong>Allocation End</strong></th>
                            <th><strong>Employee Id</strong></th>
                            <th><strong>First Name</strong></th>
                            <th><strong>Last Name</strong></th>
                            <th><strong>Action</strong></th>
                          </tr>
                        </thead>
                        <tbody class="table-border-bottom-0">
                          <tr v-for="contract in vehicleContractDetail" v-if="vehicleContractDetail.length>0">
                            
                            <td>{{$filters.dateFormat(contract.AllocationStart)}}</td>
                            <td>{{$filters.dateFormat(contract.AllocationEnd)}}</td>
                            <td>{{contract.EmployeeID}}</td>
                            <td>{{contract.FirstName}}</td>
                            <td>{{contract.LastName}}</td>
                            <td> <router-link target="_blank" class="waves-effect" :to="{ name: 'report', params: { type: 'vehicle-employee-history' }, query: { filter_by: 'employee', employee_id: contract.EmployeeID } }">View details</router-link></td>
                          </tr>
                          <tr v-else>
                              <td colspan="8" class="text-center">No record found.</td>
                          </tr>
                        </tbody>
                      </table>
                   </div>
                </div>

                <div class="tab-pane fade" id="navs-justified-history" role="tabpanel">
                  <div class="table-responsive scrollable-table ">
                      <table class="table w-100">
                        <thead>
                          <tr>
                            <th><strong>Vehicle Expense Date</strong></th>
                            <!-- <th><strong>Cost Centre</strong></th> -->
                            <th><strong>Expense Description</strong></th>
                            <th><strong>Net Expense Amount</strong></th>
                            <th><strong>Supplier Name</strong></th>
                          </tr>
                        </thead>
                        <tbody class="table-border-bottom-0">
                          <tr v-for="spend in vehicleSpendDetail" v-if="vehicleSpendDetail.length>0">
                            
                            <td>{{$filters.dateFormat(spend.VehExpenseDate)}}</td>
                            <!-- <td>{{spend.CostCentre}}</td> -->
                            <td>{{spend.ExpenseDescription}}</td>
                            <td>{{$filters.numberFormat(spend.NetExpenseAmt)}}</td>
                            <td>{{spend.SupplierName}}</td>
                          </tr>
                          <tr v-else>
                              <td colspan="8" class="text-center">No record found.</td>
                          </tr>
                        </tbody>
                      </table>
                   </div>
                </div>

              </div>
            </div>
          </div>
          <div class="card" v-if="isEmptyVehicle">
              <div class="card-body">
                  <div class="row g-3">
                      <div >No record found.</div>
                  </div>
              </div>
          </div> 

        </div>
    </div>
</template>

<script>
import {Form, Field ,useFieldError,useFormErrors,useValidateForm,useField,useForm} from 'vee-validate';

export default {
    name: 'vehicle-employee-history',
    components:{
      'VeeForm': Form,
      Field,
    },
    props: [],
    data(){
        return{
           filter_by: "vehicle",
           employeeData: {},
           employeeContractDetail: [],
           vehicleData:{},
           vehicleContractDetail: [],
           vehicleSpendDetail: [],
           filter: {
              "employee_id":"",
              "reg_number":"",
           },
           loading: false,
           isEmptyEmployee: false,
           isEmptyVehicle: false,
           downloading_complete: false,
           csv_file_name: "",
        }   
    },
    created() {
   
    },
    mounted() {

      if(this.$route.query.filter_by){
        this.filter_by= this.$route.query.filter_by;
      }
      if(this.$route.query.reg_number){
        this.filter.reg_number = this.$route.query.reg_number;
      }
      if(this.$route.query.employee_id){
        this.filter.employee_id = this.$route.query.employee_id;
      }
    },
    setup() {
    },
    computed:{
        
    },
    watch:{
      filter_by: function(newVal,oldVal){
        if(oldVal !== newVal){

           this.filter = {
                      "employee_id":"",
                      "reg_number":"",
                   };

          if(newVal == 'employee'){
            this.vehicleData = {};
            this.vehicleContractDetail = [];
            this.isEmptyVehicle = false;
          }

          if(newVal == 'vehicle'){
            this.employeeData = {};
            this.employeeContractDetail = [];
            this.isEmptyEmployee = false;
          }
        }
      },
    },

    methods:{
      async submit(){
        var self = this;
        const form = this.$refs.filterForm;
        const isValid = await form.validate();

        if (isValid.valid) {
          if(self.filter_by == 'employee'){
              self.getEmployeeData();
          }
          if(self.filter_by == 'vehicle'){
            self.getVehicleData();
          }
          
        }
      },

      getEmployeeData(){
          var self = this;
          self.loading = true;
          self.vehicleData = {};
          self.vehicleContractDetail = [];
          self.vehicleSpendDetail = [];

          self.$axios.post('/employee', self.filter)
          .then(response => {
            if (!response.data.error){ 
                self.employeeData = response.data.input_data.employee;
                self.employeeContractDetail = response.data.input_data.contract_detail;
                self.loading = false;

                if(response.data.input_data.employee === null){
                  self.isEmptyEmployee = true;
                }
                else{
                  self.isEmptyEmployee = false;
                }
            }
          })
          .catch(error => {
            console.error(error);
          });
      },

      getVehicleData(){
          var self = this;
          self.loading = true;
          self.employeeData = {};
          self.employeeContractDetail = [];

          self.$axios.post('/vehicle', self.filter)
          .then(response => {
            if (!response.data.error){ 
                self.vehicleData = response.data.input_data.vehicle;
                self.vehicleContractDetail = response.data.input_data.contract_detail;
                self.vehicleSpendDetail = response.data.input_data.spend_detail;
                self.loading = false;

                if(response.data.input_data.vehicle === null){
                  self.isEmptyVehicle = true;
                }
                else{
                  self.isEmptyVehicle = false;
                }
            }
          })
          .catch(error => {
            console.error(error);
          });
      },

      downloadCsv() {

        var self = this;

        var type = 'vehicle';
        var key = 'reg_number';
        var value = self.filter.reg_number;

        if(self.filter_by == 'employee'){
          type = 'employee';
          key = 'employee_id';
          value = self.filter.employee_id;
        }

        const apiUrl = '/download-csv/'+type+'?'+key+'='+value;

        self.$axios.get(apiUrl)
        .then(response => {
            self.downloading_complete = true;
            self.csv_file_name = response.data.input_data.csv_file_name;

            //self.$store.commit('SET_CO2_CSV_DOWNLOADING', false);
        })
        .catch(error => {
            console.error('Error downloading CSV', error);
        });
        
      },

      resetDownloadStatus() {
          this.downloading_complete = false;
      },
    },
}
</script>
