<style type="text/css">
   /* .form-control{
        margin-left: 10px;
    }
*/
</style>
<template>
   <div class="container-xxl flex-grow-1 container-p-y">
       <h4 class="fw-bold mb-4">Caveats - Details</h4>
        <div class="card">
            <div class="card-body">
                <VeeForm  ref="userForm" as="div" class="needs-validation" name="user" v-slot="{ errors }">
                  <form class="validate-form mt-2 pt-50" method="POST" action="/">
                    <div class="row g-3">
                        <div class="col-md-6">
                            <div class="mb-3" v-if="$store.state.AppConfig.loggedin_user.role_name =='superadmin'">
                              <label class="form-label" for="client"><strong>Client<span class="required">*</span></strong></label>
                              <select
                                id="client"
                                name="client"
                                class="select2 form-select form-control"
                                aria-label="Default select example"
                                v-model="caveats_det.client_id"
                                rules="required|computesRequired"
                                :disabled="$route.params.id == 'create' ? false : true"
                              >
                                <option value="">--Select--</option>
                                <option v-if="$store.state.AppConfig.customers.length" v-for="customer in $store.state.AppConfig.customers" :value="customer.CustomerID">{{customer.CompanyName}}</option>
                              </select>
                            </div>

                            <div class="mb-3">
                              <label class="form-label" for="formtabs-address"><strong>Caveats<span class="required">*</span></strong></label>
                              <Field as="textarea" 
                                  class="form-control"
                                  rows="4"
                                  name="notes" 
                                  rules="required"
                                  v-model="caveats_det.caveats" 
                                  placeholder="Caveats"
                                  label="Caveats">
                              </Field>
                              <small class="text-danger">{{ errors.caveats }}</small>
                            </div>
                        </div>                   
                    </div>
                    
                    <div class="pt-4">
                        <button type="button" @click="submitForm" class="btn btn-primary me-sm-3 me-1 waves-effect waves-light">Submit</button>
                        <router-link  class="btn btn-label-primary waves-effect" to="/caveats/list">Cancel</router-link>
                      </div>
                  </form>
                </VeeForm>
              
            </div>
        </div>
    </div>
</template>

<script>
import jQuery from 'jquery'
import moment from 'moment';
import {Form, Field ,useFieldError,useFormErrors,useValidateForm,useField,useForm, defineRule} from 'vee-validate';
import vSelect from 'vue-select';
import 'vue-select/dist/vue-select.css';
import '../../js/validation';
defineRule('computesRequired', value => {
	console.log("computesRequired value", value);
	if (['', null, undefined].indexOf(value) === -1) {
		return 'This field is required';
	}
	return true;
});

export default {
    name: 'detail',
    props: ['shared'],
    components:{
        'VeeForm': Form,
        Field,
        'v-select': vSelect,
    },
    data(){
        return{
        	caveats_det: {
        		client_id: '',
        		caveats: ''
            },
            caveatId : this.$route.params.id,
        }   
    },
    mounted() {
    	let self = this;
    	self.caveats_det.client_id = self.$store.state.AppConfig.client_id;
    	self.$eventBus.on('appconfig-loaded', (val) => {
			self.caveats_det.client_id = self.$store.state.AppConfig.client_id;
        });
    },
    created() {
        var self =this;
        if(self.caveatId && self.caveatId != 'create'){
            self.getCaveatDetail();
        }
    },

    computed:{
    },

    methods:{
        async submitForm() {
            const form = this.$refs.userForm;
            const isValid = await form.validate();

            if (isValid.valid) {
                if(this.caveats_det.id){
                  this.updateCaveat();
                }
                else{
                  this.storeCaveat();
                }
            }
        },

        storeCaveat(){
          var self = this;
          self.$axios.post('/caveats', self.caveats_det)
            .then(response => {
              if (!response.data.error){                    
                  self.$router.push({ path: '/caveats/list' });
              }
            })
            .catch(error => {
              console.error(error);
            });
        },

        updateCaveat(){
          var self = this;
          self.$axios.put('/caveats/'+self.caveatId, self.caveats_det)
            .then(response => {
              if (!response.data.error){                    
                  toastr['success']('Caveats Updated Successfully', 'Success', {
                      closeButton: true,
                      tapToDismiss: false,
                      timeOut: 5000,
                      rtl: false,
                  });
              }
            })
            .catch(error => {
              console.error(error);
            });
        },

        getCaveatDetail(){
            var self = this;
            self.$axios.get('/caveats/'+self.caveatId)
              .then(response => {
                if (!response.data.error){                    
                    self.caveats_det = response.data.input_data;
                }
              })
              .catch(error => {
                console.error(error);
              });
        },
    },

    watch:{
        
    },
}
</script>