<style type="text/css">
    .table th{
        text-transform: none !important;
    }

</style>
<template>
    <div>
        <div class="card">
          <h5 class="card-header">Download History </h5>
          <div class="table-responsive text-nowrap">
            <table class="table">
                <thead>
                    <tr>
                      <th><strong>Report Name</strong></th>
                      <th><strong>Filters</strong></th>
                      <th><strong>User</strong></th>
                      <th><strong>Date Time</strong></th>
                      <th><strong>Status</strong></th>
                      <th><strong>Report Link</strong></th>
                    </tr>
                  </thead>
                  <tbody class="table-border-bottom-0">
                    <tr v-for="history in downloadHistory">
                      <td> <span class="fw-medium">{{history.report_name}}</span></td>
                      <td> 
                        <div v-for="(filter, index) in history.report_request_data" :key="index">
                          <template v-if="filter !== null && filter !== '' && (!Array.isArray(filter) || filter.length > 0) && index != 'per_page'">
                              <template v-if="index == 'start_date' || index == 'end_date'">
                                {{ getListType(index) }} : <span>{{$filters.dateFormat(filter)}}</span>
                              </template>
                              <template v-else-if="Array.isArray(filter) && filter.length > 0">
                                {{ getListType(index) }} : <span>{{ filter.join(', ') }}</span>
                              </template>
                              <template v-else>
                                {{ getListType(index) }} : <span>{{ filter }}</span>
                              </template>
                          </template>
                        </div>
                      </td>
                      <td>
                          <div class="d-flex justify-content-start align-items-center user-name">
                            <div class="avatar-wrapper"><div class="avatar me-3"><img :src="'images/avatars/'+history.uploaded_by_photo" alt="Avatar" class="rounded-circle"></div></div> 
                            <div class="d-flex flex-column"><a href="javascript::void(0)" class="text-body text-truncate">
                              <span class="fw-medium">{{history.uploaded_by_full_name}}</span></a>
                            </div>
                          </div>
                      </td>
                      <td>{{$filters.datetimeFormat(history.created_date)}}</td>
                      <td>
                        <span v-if="history.report_status=='completed'">Completed</span>
                        <span v-if="history.report_status=='pending'">Pending</span>
                        <span v-if="history.report_status=='in progress'">In Progress</span>
                      </td>
                      <td> 
                        <div class="d-flex align-items-center" v-if="history.report_status=='completed'">
                            <a download :href="'/files/'+history.csv_file_name" class="text-body downlaod-record"><i class="ti ti-download ti-sm mx-2"></i></a>
                        </div>
                      </td>
                    </tr>
                    <tr v-if="downloadHistory.length==0">
                      <td colspan="4" class="text-center">No Record Found.</td>
                    </tr>
                </tbody>
            </table>
          </div>
          <spinner :active="loading" :is-full-page="true"></spinner> 
        </div>
    </div>
</template>

<script>

export default {
    name: 'reports',
    components:{
    },
    props: [],
    data(){
        return{
           downloadHistory: [],
           loading: false
        }   
    },

    created() {
        var self =this;
        self.getDownloadHistory();
    },
    setup() {
    },

    computed: {
    },

    methods:{
      getDownloadHistory(){
        var self = this;
        self.loading = true;
        self.$axios.get('/download-history')
          .then(response => {
            if (!response.data.error){                    
              self.downloadHistory = response.data.input_data.map(item => {
                item.report_request_data = JSON.parse(item.report_request_data);
                item.csv_file_name = this.computedCsvFileName(item);
                return item;
              });
              self.loading = false;
            }
          })
          .catch(error => {
            console.error(error);
          });
      },

      computedCsvFileName(history) {
        return history.report_name.replace(/\s+/g, "") + "_" + history.created_date + ".csv";
      },

      getListType(list_name)
        {
            if(list_name == "country")
            {
                return 'Country'
            }
            else if(list_name == "division")
            {
                return 'Division'
            }
            else if(list_name == "supplier")
            {
                return 'Supplier'
            }
            else if (list_name == "fe_description") 
            {
                return 'Expense Description'
            }
            else if (list_name == "start_date") 
            {
                return 'Start Date'
            }
            else if (list_name == "end_date")
            {
                return 'End Date'
            }
            else if (list_name == "currency")
            {
                return 'Currency'
            }
            else if (list_name == "make")
            {
                return "Make"
            }
            else if (list_name == "fuel_type")
            {
                return "Fuel Type"
            }
            else if (list_name == "vehicle_type")
            {
                return 'Vehicle Type'
            }
            else if (list_name == "invoice_number")
            {
                return 'Invoice Number'
            }
            else if (list_name == "employee_id")
            {
                return 'EmployeeID'
            }
            else if (list_name == "reg_number")
            {
                return 'Reg. Number'
            }
            else
            {
                return list_name
            }
        },
        
    },

    watch:{
        
    },
}
</script>
