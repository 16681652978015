import moment from 'moment'
var config=JSON.parse(localStorage.getItem("config"));
const filters = {
	timeFormat(value,format){
		if (!value){
			return null;
		}
		if (value.toLowerCase() == "off"){
			return value;
		}
		if (config != null && config.timeFormat != null && config.timeFormat == 'H'){
			format = 'HH:mm';
		}
		if (!format)	{
			format = 'hh:mm a';
		}
		return moment(value,'HH:mm:ss').format(format);
	},
	dateFormat(value,format){
		if (!value){
			return null;
		}
		format = 'DD-MM-YYYY';
		return moment(value).format(format);
	},
	normalTimeFormat(value,format){
		if (!value){
			return '00:00';
		}
		if(value){
			var hours= Math.floor(value / 3600);
			// calculate minutes 
			var minutes = Math.floor(value % 3600 / 60);
			// calculate seconds 
		    var seconds = Math.floor(value % 3600 % 60);
		    if(hours && value >= 3600){
				return moment(hours+':'+minutes+':'+seconds,'hh:mm:ss').format('hh:mm:ss');
		    }
		  	return moment(minutes+':'+seconds,'mm:ss').format('mm:ss');
		}
		return '00:00';
	},
	datetimeFormat(value,format){
		if (!value){
			return null;
		}
		if (config != null && config.PortalDateFormat != null){
			format = config.PortalDateFormat;
			if (config.timeFormat != null && config.timeFormat == 'H'){
				format = format+' HH:mm';
			}else{
				format = format+' hh:mm a';
			}
		}else{
			format = 'YYYY-MMM-DD hh:mm a';
		}
		return moment(value* 1000).format(format);
	},
	capitalize(value,replacer="_"){
		if (!value) return ''
			value = value.toString()

		let arr = value.split(replacer)
		let capitalized_array = []
		arr.forEach((word) => {
			let capitalized = word.charAt(0).toUpperCase() + word.slice(1)
			capitalized_array.push(capitalized)
		})
		return capitalized_array.join(" ");
	},
	hrMnTimeFormat(value,format){
		if (!value){
			return null;
		}
		if (value.toLowerCase() == "off"){
			return value;
		}
		if (config != null && config.timeFormat != null && config.timeFormat == 'H'){
			format = 'HH:mm';
		}
		if (!format)	{
			format = 'hh:mm a';
		}
		return moment(value,'HH:mm:ss').format(format);
	
	},
	timeFormat(value,format=null){

		if (!value){
			return null;
		}

		var format = 'hh:mm A';
		if (config != null && config.timeFormat != null && config.timeFormat == 'H'){
			format = 'HH:mm';
		}
		if (!format)	{
			format = 'hh:mm A';
		}
		if (!value) {
			return null;
		}
		return moment(value).format(format);
	},
	monthDatetimeFormat(value,format){
		if (!value){
			return null;
		}
		if (config != null && config.PortalDateFormat != null){
			format = config.PortalDateFormat;
			if (config.timeFormat != null && config.timeFormat == 'H'){
				format = format+' HH:mm';
			}else{
				format = format+' hh:mm a';
			}
		}else{
			format = 'MMM DD hh:mm a';
		}
		return moment(value).format(format);
	},
	showHumanSecondTime(value) {	
		var hours= Math.floor(value / 3600);
		var minutes = Math.floor(value % 3600 / 60);
	    var seconds = Math.floor(value % 3600 % 60);
	    if(hours && value >= 3600){
	    	return (hours < 10 ? this.strPadLeft(hours,'0',2) : hours) + "h " + (minutes < 10 ? this.strPadLeft(minutes,'0',2) : minutes) + "m " +(seconds < 10 ? this.strPadLeft(seconds,'0',2) : seconds ) + 's';
	    }
	    return (minutes < 10 ? this.strPadLeft(minutes,'0',2) : minutes) + "m " + (seconds < 10 ? this.strPadLeft(seconds,'0',2) : seconds ) + 's';
	},
	showFormatedTimer(value) {	
		var minutes = Math.floor(value / 60);
	    var seconds = value - minutes * 60;
	    return (minutes < 10 ? this.strPadLeft(minutes,'0',2) : minutes) + "m " + (seconds < 10 ? this.strPadLeft(seconds,'0',2) : seconds )+"s";
	},

	showHumanSecondTime(value) {	
		var hours= Math.floor(value / 3600);
		var minutes = Math.floor(value % 3600 / 60);
	    var seconds = Math.floor(value % 3600 % 60);
	    if(hours && value >= 3600){
	    	return (hours < 10 ? this.strPadLeft(hours,'0',2) : hours) + "h " + (minutes < 10 ? this.strPadLeft(minutes,'0',2) : minutes) + "m " +(seconds < 10 ? this.strPadLeft(seconds,'0',2) : seconds ) + 's';
	    }
	    return (minutes < 10 ? this.strPadLeft(minutes,'0',2) : minutes) + "m " + (seconds < 10 ? this.strPadLeft(seconds,'0',2) : seconds ) + 's';
	},
	
	strPadLeft(string,pad,length) {
		return (new Array(length+1).join(pad)+string).slice(-length);
	},
	getOnlyCharacters(value){
		if (!value) return '';
		const matchedCharacters = value.match(/[A-Za-z]/g);  
  		return matchedCharacters ? matchedCharacters.join('') : '';
	}, 
	convertSizeToReadable(value){
		if (!value) return '';
		if (value < 1024) {
			return value + ' B';
		} else if (value < 1024 * 1024) {
			return (value / 1024).toFixed(2) + ' KB';
		} else if (value < 1024 * 1024 * 1024) {
			return (value / (1024 * 1024)).toFixed(2) + ' MB';
		} else {
			return (value / (1024 * 1024 * 1024)).toFixed(2) + ' GB';
		}
	},
	timeInMinutesAndSeconds(chatMinutes){
		if(!chatMinutes){
			return "N.A.";
		}
		else
		{
			let minutes, seconds;
			minutes = Math.floor(chatMinutes);
			seconds = Math.floor((chatMinutes * 60) - (minutes * 60));
			return `${minutes}m ${seconds}s`;
		}
	},
	portalConfigDateTzFormat(value,format) {
		var date_time_format = '';
		if (!value){
			return null;
		}
		if (format != null) {
			date_time_format = config.PortalDateFormat	+ " " + format
		}else{
			date_time_format = config.PortalDateFormat	+" hh:mm a";
		}
		return moment(value).format(date_time_format);
	},
	timeFromSecondsInto(seconds){
		if(!seconds){
			return 'N.A';
		}
		else{
			seconds = parseInt(seconds);
			const h = Math.floor(seconds / 3600);
			const m = Math.floor((seconds % 3600) / 60);
			const s = seconds % 60;
			const timeArray = [];

			if (h > 0) timeArray.push(h + 'h');
			if (m > 0) timeArray.push(m + 'm');
			if (s > 0 || timeArray.length === 0) timeArray.push(s + 's');

			return timeArray.join(' ');
		}
	},
	numberFormat(value){
		if(!value){
			return null;
		}
		else{
			return parseFloat(value).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 });
		}
	},

}


export default filters;