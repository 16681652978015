<style type="text/css">
    .required {
        color: #d16e6c;
        font-weight: bold;
        padding-left: 3px;
    }

</style>
<template>
    <div class="content-wrapper">
        <div class="container-xxl flex-grow-1 container-p-y">
          <!-- <h4 class="fw-bold ">Costs</h4> -->

          <div class="row justify-content-between mb-2">
              <div class="col-auto">
                <div class="d-flex align-items-center gap-2">
                  <!-- <router-link  class="btn btn-icon btn-outline-primary waves-effect" to="/dashboard"><span class="ti ti-arrow-left"></span></router-link> -->
                  <h4 class="fw-bold mb-1"> Costs</h4>
                </div>
              </div>
              <div class="col-auto gap-2">
                <div class="d-flex align-items-center gap-2">
                  <button type="button" @click="downloadCsv" class="btn btn-outline-primary waves-effect">
                    <span class="ti-xs ti ti-download me-1"></span>Download CSV
                  </button>

                  <!-- <button v-if="$store.state.AppConfig.costCSVDownloading" class="btn btn-primary" type="button" disabled>
                    <span class="spinner-border me-1" role="status" aria-hidden="true"></span>
                    Downloading...
                  </button>

                  <router-link v-if="downloading_complete" @click="resetDownloadStatus" class="btn btn-primary waves-effect" :to="'/files/'+csv_file_name" target="_blank"><span class="ti-xs ti ti-download me-1"></span>Download Ready</router-link> -->

                  <router-link  class="btn btn-outline-primary waves-effect" to="/download-history">Download History</router-link>
                </div>
              </div>
          </div>

          <div class="card mb-4">
            <h5 class="card-header border-bottom">Filters</h5>
            <VeeForm  ref="filterForm" as="div" class="needs-validation" name="filter" v-slot="{ errors }">
              <form class="card-body">
                <div class="row g-3">
                  <!-- <pre>{{filter}}</pre> -->
                  <div class="col-md-3" >
                    <!-- <label class="form-label" for="countries"><strong>Country</strong></label>
                    <select
                      id="countries"
                      multiple
                      name="countries"
                      class="select2 form-select form-control"
                      v-model="filter.country"
                    >
                      <option value="">All</option>
                      <option v-if="$store.state.AppConfig.countries.length" v-for="country in $store.state.AppConfig.countries" :value="country.Country">{{country.Country}}</option>
                    </select> -->
                      <div class="mb-1">
                        <label class="form-label" for="contact-info-vertical"><strong>Country</strong></label>
                        <v-select 
                        :options="$store.state.AppConfig.countries"
                        :value="filter.country"
                        v-model="filter.country" 
                        label="Country"
                        :clearable="false"
                        :reduce="option => option.Country" 
                        placeholder="All"
                        name="country"
                        multiple
                        />
                      </div>
                  </div>


                  <div class="col-md-3" >
                    <label class="form-label" for="divisions"><strong>Division</strong></label>
                    <select
                      id="divisions"
                      name="divisions"
                      class="select2 form-select form-control"
                      v-model="filter.division"
                    >
                      <option value="">All</option>
                      <option v-if="divisions.length" v-for="division in divisions" :value="division.Division">{{division.Division}}</option>
                    </select>
                  </div>

                  <div class="col-md-3" >
                    <label class="form-label" for="suppliers"><strong>Suppliers</strong></label>
                    <select
                      id="suppliers"
                      name="suppliers"
                      class="select2 form-select form-control"
                      v-model="filter.supplier"
                    >
                      <option value="">All</option>
                      <option v-if="$store.state.AppConfig.suppliers.length" v-for="supplier in $store.state.AppConfig.suppliers" :value="supplier.SupplierCode">{{supplier.SupplierName}}</option>
                    </select>
                  </div>

                  <div class="col-md-3" >
                    <label class="form-label" for="fe_description"><strong>Expense Description</strong></label>
                        <v-select 
                        :options="fe_descriptions"
                        :value="filter.fe_description"
                        v-model="filter.fe_description" 
                        label="ExpenseDescription"
                        :clearable="false"
                        :reduce="option => option['VehExpense Code']" 
                        placeholder="All"
                        name="fe_description"
                        multiple
                        />
                  </div>

                  <div class="col-md-3" >
                    <label class="form-label" for="currencies"><strong>Currency</strong></label>
                    <select
                      id="currencies"
                      name="currencies"
                      class="select2 form-select form-control"
                      v-model="filter.currency"
                    >
                      <option v-if="currencies.length" v-for="currency in currencies" :value="currency.name">{{currency.title}}</option>
                    </select>
                  </div>

                  <div class="col-md-3">
                    <div class="form-password-toggle">
                      <label class="form-label" for="multicol-confirm-password"><strong>Start Date <span class="required">*</span></strong></label>
                      <Datepicker 
                          locale="en" 
                          v-model="filter.start_date" 
                          autoApply
                          :enableTimePicker="false"
                          :monthChangeOnScroll="false"
                          format="dd-MM-yyyy"
                          >
                        </Datepicker>

                        <Field id="config-date" type="hidden" class="form-control" 
                          rules="required"  name="start_date" 
                          v-model="filter.start_date" 
                          label="Start Date">
                        </Field>
                        <small class="text-danger">{{ errors.start_date }}</small>
                    </div>
                  </div>

                  <div class="col-md-3">
                    <div class="form-password-toggle">
                      <label class="form-label" for="multicol-confirm-password"><strong>End Date<span class="required">*</span></strong></label>
                        <Datepicker 
                          locale="en" 
                          v-model="filter.end_date" 
                          autoApply
                          :enableTimePicker="false"
                          :monthChangeOnScroll="false"
                          format="dd-MM-yyyy"
                          >
                        </Datepicker>

                        <Field id="config-date" type="hidden" class="form-control" 
                          rules="required"  name="end_date" 
                          v-model="filter.end_date" 
                          label="End Date">
                        </Field>
                        <small class="text-danger">{{ errors.end_date }}</small>
                    </div>
                  </div>
                </div>

                <div class="pt-4">
                  <button type="button" @click="submit" class="btn btn-primary me-sm-3 me-1">Apply</button>
                  <router-link  class="btn btn-outline-primary waves-effect" to="/dashboard">Cancel</router-link>
                </div>
              </form>
            </VeeForm>
          </div>

          <div class="card mb-4">
              <h5 class="card-header">Graphical View</h5>
              <div class="card-body">
                  <div class="row g-3">
                      <div v-if="loading" class="text-center">
                        <spinner :active="loading" :is-full-page="false"></spinner>
                      </div>
                      <div class="col-md-12">
                          <canvas ref="barChart" class="chartjs" data-height="400" height="500"></canvas>
                      </div>
                  </div>
              </div>
          </div>

          <div class="card mb-4">
            <div class="card-body">
              <div class="table-responsive scrollable-table">
                <table class="table w-100">
                  <thead>
                    <tr>
                      <th><strong>Country</strong></th>
                      <th><strong>Division</strong></th>
                      <th><strong>Cost_Category</strong></th>
                      <th><strong>RegNumber</strong></th>
                      <th><strong>Invoice Number</strong></th>
                      <th><strong>VehExpenseDate</strong></th>
                      <th><strong>EmployeeCostCentre</strong></th>
                      <th><strong>Expense Description</strong></th>
                      <th><strong>Currency</strong></th>
                      <th><strong>Net Expense Amt</strong></th>
                      <th><strong>Comments</strong></th>
                      <th><strong>Supplier Name</strong></th>
                      <th><strong>EmployeeID</strong></th>
                      <th><strong>FirstName</strong></th>
                      <th><strong>LastName</strong></th>
                      <th><strong>EE_CarGrade</strong></th>
                    </tr>
                  </thead>
                  <tbody class="table-border-bottom-0">
                    <tr v-if="tblLoading">
                        <td colspan="8" class="text-center">
                          <spinner :active="tblLoading" :is-full-page="false"></spinner>
                        </td>
                    </tr>
                    <tr v-for="data in tableData" v-if="tableData.length>0 && !tblLoading">
                      <td>{{data.Country}}</td>
                      <td>{{data.Division}}</td>
                      <td>{{data.Cost_Category}}</td>
                      <td>{{data.RegNumber}}</td>
                      <td>{{data['Invoice Number']}}</td>
                      <td>{{data.VehExpenseDate}}</td>
                      <td>{{data.EmployeeCostCentre}}</td>
                      <td>{{data.ExpenseDescription}}</td>
                      <td>{{data.Currency}}</td>
                      <td>{{data.NetExpenseAmt}}</td>
                      <td>{{data.Comments}}</td>
                      <td>{{data.SupplierName}}</td>
                      <td>{{data.EmployeeID}}</td>
                      <td>{{data.FirstName}}</td>
                      <td>{{data.LastName}}</td>
                      <td>{{data.EE_CarGrade}}</td>
                    </tr>
                    <tr v-else-if="tableData.length==0 && !tblLoading">
                        <td colspan="8" class="text-center">No record found.</td>
                    </tr>
                  </tbody>
                </table>
              </div>
               <div class="px-2 d-flex justify-content-between py-1">      
                  <div>
                    <div class="flex-items">  
                      <div class="align-items-center  d-flex" style="padding-top:2px">
                        <span>Show </span>&nbsp;
                        <span>
                          <select style="width: fit-content;" class="form-select form-select-sm" v-model="recordsPerPage">
                            <option value="10">10</option>
                            <option value="25">25</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                          </select>
                        </span>&nbsp;&nbsp;
                        <span>Per Page</span>&nbsp;<span>(Total : {{totalData}})</span>
                      </div>
                    </div>
                  </div> 
                  <div>
                    <Pagination
                      v-if="totalData > recordsPerPage"
                      v-model="currentPage"
                      :activePage="currentPage" 
                      :per-page="recordsPerPage"
                      :total-rows="totalData">   
                    </Pagination>
                  </div>
              </div>
            </div>
          </div>

        </div>
    </div>
</template>

<script>
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css';
import {Form, Field ,useFieldError,useFormErrors,useValidateForm,useField,useForm} from 'vee-validate';
import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'
import Pagination from '../../components/Pagination';
import Swal from 'sweetalert2'

export default {
    name: 'costs',
    components:{
      'VeeForm': Form,
      Datepicker,
      Field,
      'v-select': vSelect,
      Pagination
    },
    props:{
      downloading: Boolean,
      pendingRequest: Object,
    },
    data(){
        return{
           myChart: "",
           divisions: [],
           makes: [],
           fe_descriptions: [],
            currencies: [
              { "name": "local", "title":"Local" },
              {"name": "Euro Conversion", "title":"Euro"},
              {"name": "USD Conversion", "title":"USD"}
            ],
           filter: {
              "country":[],
              "division": "",
              "supplier": "",
              "fe_description":[],
              "currency":"Euro Conversion",
              "start_date":"",
              "end_date":"",
           },
           chartData:[],
           barChartVar: null,
           cyanColor: '#00bcd4',
            yAxisMin: 0,
            yAxisMax: 400,
            loading: false,
            downloading_complete: false,
            csv_file_name: "",
            currentPage:1,
            recordsPerPage:10,
            tableData: [],
            totalData: 0,
            tblLoading: false
        }   
    },
    created() {
      var self = this;
      self.getDivisions();
      self.getFeDiscriptions();

      const currentDate = new Date();
      const startDate = new Date(currentDate);
      startDate.setMonth(currentDate.getMonth() - 12);
      self.filter.start_date = startDate;
      self.filter.end_date = currentDate;

      if(self.filter.start_date && self.filter.end_date){
        self.getGraphData();
        self.getTableData();
      }
    },
    mounted() {
    },
    setup() {
    },
    computed:{
        
    },

    methods:{
      getDivisions(){
        var self = this;
        self.$axios.get('/divisions')
          .then(response => {
            if (!response.data.error){                    
                self.divisions = response.data.input_data;
            }
          })
          .catch(error => {
            console.error(error);
          });
      },

      getFeDiscriptions(){
        var self = this;
        self.$axios.get('/fe_descriptions')
          .then(response => {
            if (!response.data.error){                    
                self.fe_descriptions = response.data.input_data;
            }
          })
          .catch(error => {
            console.error(error);
          });
      },

      async submit(){
        var self = this;
        const form = this.$refs.filterForm;
        const isValid = await form.validate();

        if (isValid.valid) {
          self.getGraphData();
          self.getTableData();
        }
      },

      getGraphData(){
          var self = this;
          self.loading = true;
          self.$axios.post('/cost-graph', self.filter)
          .then(response => {
            if (!response.data.error){ 
                self.chartData = response.data.input_data;
                self.loading = false;
               
                const dataValues = this.chartData.map(item => item.data);
                self.yAxisMin = Math.min(...dataValues) - ((2 / 100) * Math.min(...dataValues));
                self.yAxisMax = Math.max(...dataValues) + ((2 / 100) * Math.max(...dataValues));
               
                self.updateChart();
            }
          })
          .catch(error => {
            console.error(error);
          });
      },

      updateChart() {
          const canvasElement = this.$refs.barChart;
          if (canvasElement && canvasElement.getContext) {

            const context = canvasElement.getContext('2d');
            context.clearRect(0, 0, canvasElement.width, canvasElement.height);

            const existingChart = Chart.getChart(canvasElement);
            if (existingChart) {
                existingChart.destroy();
            }

            this.barChartVar = new Chart(context, {
              type: 'line',
              data: {
               labels: this.chartData.map(item => item.label),
                datasets: [
                  {
                    data: this.chartData.map(item => item.data),
                    backgroundColor: this.cyanColor,
                    borderColor: '#2B9AFF',
                    tension: 0.5,
                    pointStyle: 'circle',
                    fill: false,
                    pointRadius: 5,
                    pointHoverRadius: 5,
                    pointHoverBorderWidth: 5,
                    pointBorderColor: 'transparent',
                    pointHoverBorderColor: "#4F5D70",
                    pointHoverBackgroundColor: "#2B9AFF"
                  }
                ]
              },
              options: {
                responsive: true,
                maintainAspectRatio: false,
                animation: {
                  duration: 500
                },
                plugins: {
                  tooltip: {
                    rtl: false,
                    backgroundColor: "#2f3349",
                    titleColor: "#cfd3ec",
                    bodyColor: "#a5a3ae",
                    borderWidth: 1,
                    borderColor: "#a5a3ae"
                  },
                  legend: {
                    display: false
                  }
                },
                scales: {
                  x: {
                    grid: {
                      color: "#dbdade",
                      drawBorder: false,
                      borderColor: "#dbdade",
                    },
                    ticks: {
                      color: "#a5a3ae",
                    }
                  },
                  y: {
                    min: this.yAxisMin,
                    max: this.yAxisMax,
                    grid: {
                      color:"#dbdade",
                      drawBorder: false,
                      borderColor: "#dbdade",
                    },
                    ticks: {
                      stepSize: (this.yAxisMax - this.yAxisMin) / 5,
                      color: "#a5a3ae",
                      callback: function (value, index, values) {
                    	  if (parseInt(value) >= 1000) {
                    		  let mathRoundVal = Math.round(value/ 10) * 10;
                    		  return mathRoundVal.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                    	  }
                    	  else {
                    		  return Math.round(value/ 10) * 10;
                    	  }
                        //return Math.round(value/ 10) * 10;
                      },
                    },
                    title: {
                        display: true,
                        text: 'Cost',
                    },
                  }
                }
              }
            });
          }
      },
      
      downloadCsv() {
        var self = this;
        self.$store.commit('SET_COST_CSV_DOWNLOADING', true);

        Swal.fire({
          title: 'Confirm',
          text: "Your request is being processed. You will be able to download the Report under 'Download History' when it is completed. In certain cases it might take a little longer, depending on the timeframe and the volume of activity.",
          confirmButtonText: 'OK'
        });

        self.$axios.post('/download-csv/costs', self.filter)
        .then(response => {
          if (!response.data.error){ 
              self.$store.commit('SET_COST_CSV_DOWNLOADING', false);
              self.downloading_complete = true;
              self.csv_file_name = response.data.input_data.csv_file_name;
          }
        })
        .catch(error => {
          console.error(error);
        });
      },

      resetDownloadStatus() {
          this.downloading_complete = false;
      },

      getTableData(){
          var self = this;
          self.tblLoading = true;

          self.filter.per_page = self.recordsPerPage;
          self.$axios.post('/costs-data/'+ self.currentPage, self.filter)
          .then(response => {
            if (!response.data.error){ 
                self.tableData = response.data.input_data.data;
                self.totalData = response.data.input_data.total;
                self.tblLoading = false;
            }
          })
          .catch(error => {
            console.error(error);
          });
      },

      loadFirstPage(){
        var self = this;
        clearTimeout(self.debounceTimeout); // Clear the previous timeout
        self.debounceTimeout = setTimeout(() => {
          self.currentPage = null;
          self.currentPage = 1;
          self.getTableData();
        }, 500);
      },

      /*downloadCsv() {

          var self = this;
          self.downloading = true;
          
          const apiUrl = '/api/service/v1/download-csv/costs';

          const xhr = new XMLHttpRequest();
          xhr.open('POST', apiUrl);
          xhr.responseType = 'blob';
          xhr.setRequestHeader('X-CSRF-TOKEN', jQuery('meta[name="csrf-token"]').attr('content'));
          //xhr.setRequestHeader('Accept', 'application/json');
          xhr.setRequestHeader('Content-Type', 'application/json');

          xhr.onload = function () {
              if (xhr.status === 200) {
                  const url = window.URL.createObjectURL(new Blob([xhr.response]));
                  const link = document.createElement('a');
                  link.href = url;
                  link.setAttribute('download', 'Costs.csv');
                  document.body.appendChild(link);
                  link.click();

                  self.downloading = false;
              } else {
                  console.error('Error downloading CSV. Status:', xhr.status);
              }
          };

          xhr.onerror = function (error) {
              console.error('Error downloading CSV', error);
          };

          xhr.send(JSON.stringify(self.filter));
      },*/

      /*downloadCsv: function () {
        var self = this;
        var url = '/download-csv/costs';
        var form = document.createElement("form");
        form.setAttribute("method", "post");
        form.setAttribute("action", url);
        //form.setAttribute("target", "_blank");

        var hiddenCSRF = document.createElement("input");
        hiddenCSRF.setAttribute("type", "hidden");
        hiddenCSRF.setAttribute("name", "_token");
        hiddenCSRF.setAttribute("value", jQuery('meta[name="csrf-token"]').attr('content'));
        form.appendChild(hiddenCSRF);

        // Add other parameters based on your filter object
        var filterKeys = Object.keys(self.filter);
        for (var i = 0; i < filterKeys.length; i++) {
            var key = filterKeys[i];
            var hiddenInput = document.createElement("input");
            hiddenInput.setAttribute("type", "hidden");
            hiddenInput.setAttribute("name", key);

            if (key === 'start_date' || key === 'end_date') {
                hiddenInput.setAttribute("value", new Date(self.filter[key]).toISOString());
            } else if (Array.isArray(self.filter[key])) {

                for (var j = 0; j < self.filter[key].length; j++) {
                    var arrayInput = document.createElement("input");
                    arrayInput.setAttribute("type", "hidden");
                    arrayInput.setAttribute("name", key + "[]");
                    arrayInput.setAttribute("value", self.filter[key][j]);
                    form.appendChild(arrayInput);
                }
                continue; // Skip adding the main hidden input for arrays
            } else {
                hiddenInput.setAttribute("value", self.filter[key]);
            }

            form.appendChild(hiddenInput);
        }

        document.body.appendChild(form);

        console.log(form);
        form.submit();
      },*/
        
    },

    watch:{
      'recordsPerPage'(newVal, oldVal){
        var self = this
        if(oldVal && newVal && newVal != oldVal){
          this.loadFirstPage();
        }
      },
      "currentPage"(newVal, oldVal){
        if(oldVal && newVal && newVal != oldVal){
          this.currentPage = newVal;
          this.getTableData();
        }
      },
    },

}
</script>
