<style type="text/css">
</style>
<template>
    <div v-if="type=='fleet-size'" :key="type">
        <FleetSize></FleetSize>
    </div>
    <!-- <div v-if="type=='fleet-list'" :key="type">
        <FleetList></FleetList>
    </div> -->
    <div v-if="type=='replacements-due'" :key="type">
        <ReplacementsDue></ReplacementsDue>
    </div>
    <div v-if="type=='co2'" :key="type">
        <CO2></CO2>
    </div>
    <div v-if="type=='carbon-footprint'" :key="type">
        <CarbonFootprint></CarbonFootprint>
    </div>
    <div v-if="type=='costs'">
        <Costs></Costs>
    </div>
    <div v-if="type=='vehicle-on-order'" :key="type">
        <VehicleOnOrder></VehicleOnOrder>
    </div>
    <div v-if="type=='invoice'" :key="type">
        <Invoice></Invoice>
    </div>
    <div v-if="type=='vehicle-employee-history'" :key="type">
        <VehicleEmployeeHistory></VehicleEmployeeHistory>
    </div>
</template>

<script>

import FleetSize from './report_details/FleetSize';
//import FleetList from './report_details/FleetList';
import ReplacementsDue from './report_details/ReplacementsDue';
import CO2 from './report_details/CO2';
import CarbonFootprint from './report_details/CarbonFootprint';
import Costs from './report_details/Costs';
import VehicleOnOrder from './report_details/VehicleOnOrder';
import Invoice from './report_details/Invoice';
import VehicleEmployeeHistory from './report_details/VehicleEmployeeHistory';

export default {
    name: 'reportDetail',
    components:{
      FleetSize,
      // FleetList,
      ReplacementsDue,
      CO2,
      CarbonFootprint,
      Costs,
      VehicleOnOrder,
      Invoice,
      VehicleEmployeeHistory
    },
    props: [],
    data(){
        return{
           type: this.$route.params.type,
        }   
    },
    created() {

        
    },
    mounted() {
        
    },
    setup() {
    },
    computed:{
        
    },

    methods:{
      
    },

   watch: {
    '$route.params.type'(newType) {
      this.type = newType;
    },
  },
}
</script>
