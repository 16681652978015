<style type="text/css">
    

</style>
<template>
     <div class="container-xxl">
        <div class="content-body">
          <div class="authentication-wrapper authentication-basic container-p-y">
            <div class="authentication-inner py-4">
              <div class="card">
                <div class="card-body">
                  <div class="app-brand justify-content-center mb-4 mt-2">
                    <a href="index.html" class="app-brand-link gap-2">
                      <span class="app-brand-logo demo">
                        <img src="/images/fleetworx.jpg" style="max-width:180px; max-height:180px;">
                      </span>
                    </a>
                  </div>
                  <form  name="frm_login" id="frm_login" class="mb-3"  method="POST" action="" @submit.prevent="login">
                  <input type="hidden" name="_token"  v-model="csrf_token">
                    <div class="mb-3 form-password-toggle">
                      <div class="input-group input-group-merge">
                        <input
                          type="password"
                          id="otp"
                          class="form-control"
                          name="otp"
                          v-model="otp"
                          placeholder="Enter OTP"
                          aria-describedby="otp"
                        />
                      </div>
                    </div>
                    <div class="mb-3">
                      <button type="submit"  class="btn btn-primary d-grid w-100">Login</button>
                    </div>
                 </form>
                </div>
              </div>
            </div>
          </div>
        </div>
    </div>
</template>

<script>
import jQuery from "jquery";
import '../../../../public/v3-assets/vendor/css/pages/page-auth.css';

export default {
    name: 'loginOtp',
    components:{
    },
    props: [],
    data(){
        return{
           otp: '',
           csrf_token: jQuery('meta[name="csrf-token"]').attr('content'),
        }   
    },

    created() {
    },
    setup() {
   
    },

    computed:{
        
    },

    methods:{

      login() {

         jQuery('#frm_login').submit();
        /*var self = this;
         self.$axios.post('/login/otp', {
          user_otp: this.otp,
        })
        .then(response => {
          console.log(response.data);
        })
        .catch(error => {
          console.error(error);
        });*/
      },

       
    },

    watch:{
        
    },
}
</script>
