<style type="text/css">
    .required {
        color: #d16e6c;
        font-weight: bold;
        padding-left: 3px;
    }

</style>
<template>
    <div class="content-wrapper">
       <div class="container-xxl flex-grow-1 container-p-y">
          <!-- <h4 class="fw-bold">Carbon Footprint</h4> -->

          <div class="row justify-content-between mb-2">
              <div class="col-auto">
                  <h4 class="fw-bold mb-1">Carbon Footprint</h4>
              </div>
              <div class="col-auto" >
                  <button type="button" @click="downloadCsv" class="btn btn-outline-primary waves-effect">
              <span class="ti-xs ti ti-download me-1"></span>Download CSV
            </button>
              </div>
          </div>

          <div class="card mb-4">
            <h5 class="card-header border-bottom">Filters</h5>
            <VeeForm  ref="filterForm" as="div" class="needs-validation" name="filter" v-slot="{ errors }">
              <form class="card-body">
                <div class="row g-3">
                  <!-- <pre>{{filter}}</pre> -->
                  <div class="col-md-3" >
                    <label class="form-label" for="countries"><strong>Country</strong></label>
                    <select
                      id="countries"
                      name="countries"
                      class="select2 form-select form-control"
                      v-model="filter.country"
                    >
                      <option value="">All</option>
                      <option v-if="$store.state.AppConfig.countries.length" v-for="country in $store.state.AppConfig.countries" :value="country.Country">{{country.Country}}</option>
                    </select>
                  </div>

                  <div class="col-md-3">
                    <label class="form-label" for="makes"><strong>Make</strong></label>
                    <select
                      id="makes"
                      name="makes"
                      class="select2 form-select form-control"
                      v-model="filter.make"
                    >
                      <option value="">All</option>
                      <option v-if="makes.length" v-for="make in makes" :value="make.Make">{{make.Make}}</option>
                    </select>
                  </div>

                  <div class="col-md-3" >
                    <label class="form-label" for="divisions"><strong>Division</strong></label>
                    <select
                      id="divisions"
                      name="divisions"
                      class="select2 form-select form-control"
                      v-model="filter.division"
                    >
                      <option value="">All</option>
                      <option v-if="divisions.length" v-for="division in divisions" :value="division.Division">{{division.Division}}</option>
                    </select>
                  </div>

                  <div class="col-md-3" >
                    <label class="form-label" for="vehicle_types"><strong>Vehicle Type</strong></label>
                    <select
                      id="vehicle_types"
                      name="vehicle_types"
                      class="select2 form-select form-control"
                      v-model="filter.vehicle_type"
                    >
                      <option value="">All</option>
                      <option v-if="vehicle_types.length" v-for="vehicle_type in vehicle_types" :value="vehicle_type['Vehicle Type']">{{vehicle_type['Vehicle Type']}}</option>
                    </select>
                  </div>

                  <div class="col-md-3" >
                    <label class="form-label" for="fuel_types"><strong>Fuel Type</strong></label>
                    <select
                      id="fuel_types"
                      name="fuel_types"
                      class="select2 form-select form-control"
                      v-model="filter.fuel_type"
                    >
                      <option value="">All</option>
                      <option v-if="fuel_types.length" v-for="fuel_type in fuel_types" :value="fuel_type['Fuel Type']">{{fuel_type['Fuel Type']}}</option>
                    </select>
                  </div>

                  <div class="col-md-3">
                    <div class="form-password-toggle">
                      <label class="form-label" for="multicol-confirm-password"><strong>Start Date <span class="required">*</span></strong></label>
                      <Datepicker 
                          locale="en" 
                          v-model="filter.start_date" 
                          autoApply
                          :enableTimePicker="false"
                          :monthChangeOnScroll="false"
                          format="dd-MM-yyyy"
                          >
                        </Datepicker>

                        <Field id="config-date" type="hidden" class="form-control" 
                          rules="required"  name="start_date" 
                          v-model="filter.start_date" 
                          label="Start Date">
                        </Field>
                        <small class="text-danger">{{ errors.start_date }}</small>
                    </div>
                  </div>

                  <div class="col-md-3">
                    <div class="form-password-toggle">
                      <label class="form-label" for="multicol-confirm-password"><strong>End Date<span class="required">*</span></strong></label>
                        <Datepicker 
                          locale="en" 
                          v-model="filter.end_date" 
                          autoApply
                          :enableTimePicker="false"
                          :monthChangeOnScroll="false"
                          format="dd-MM-yyyy"
                          >
                        </Datepicker>

                        <Field id="config-date" type="hidden" class="form-control" 
                          rules="required"  name="end_date" 
                          v-model="filter.end_date" 
                          label="End Date">
                        </Field>
                        <small class="text-danger">{{ errors.end_date }}</small>
                    </div>
                  </div>
                </div>

                <div class="pt-4">
                  <button type="button" @click="submit" class="btn btn-primary me-sm-3 me-1">Apply</button>
                  <router-link  class="btn btn-outline-primary waves-effect" to="/dashboard">Cancel</router-link>
                </div>
              </form>
            </VeeForm>
          </div>

          <div class="card">
                <h5 class="card-header">Graphical View</h5>
                <div class="card-body">
                    <div class="row g-3">
                        <div class="col-md-12">
                            <canvas ref="barChart" class="chartjs" data-height="400" height="500"></canvas>
                        </div>
                    </div>
                    <spinner :active="loading" :is-full-page="true"></spinner> 
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {Form, Field ,useFieldError,useFormErrors,useValidateForm,useField,useForm} from 'vee-validate';
import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'

export default {
    name: 'carbon-footprint',
    components:{
      'VeeForm': Form,
      Datepicker,
      Field,
    },
    props: [],
    data(){
        return{
           myChart: "",
           divisions: [],
           makes: [],
           vehicle_types: [],
           fuel_types: [],
           filter: {
              "country":"",
              "division":"",
              "make":"",
              "fuel_type": "",
              "vehicle_type": "",
              "start_date":"",
              "end_date":"",
           },
           chartData:[],
           barChartVar: null,
           cyanColor: '#00bcd4',
            yAxisMin: 0,
            yAxisMax: 400,
            loading: false
        }   
    },
    created() {
      var self = this;
      self.getDivisions();
      self.getMakes();
      self.getFuelTypes();
      self.getVehicleTypes();

      const currentDate = new Date();
      const startDate = new Date(currentDate);
      startDate.setMonth(currentDate.getMonth() - 12);
      self.filter.start_date = startDate;
      self.filter.end_date = currentDate;

      if(self.filter.start_date && self.filter.end_date){
        self.getGraphData();
      }
    },
    mounted() {
    },
    setup() {
    },
    computed:{
        
    },

    methods:{
      getDivisions(){
        var self = this;
        self.$axios.get('/divisions')
          .then(response => {
            if (!response.data.error){                    
                self.divisions = response.data.input_data;
            }
          })
          .catch(error => {
            console.error(error);
          });
      },

      getMakes(){
        var self = this;
        self.$axios.get('/makes')
          .then(response => {
            if (!response.data.error){                    
                self.makes = response.data.input_data;
            }
          })
          .catch(error => {
            console.error(error);
          });
      },

      getVehicleTypes(){
        var self = this;
        self.$axios.get('/vehicle_types')
          .then(response => {
            if (!response.data.error){                    
                self.vehicle_types = response.data.input_data;
            }
          })
          .catch(error => {
            console.error(error);
          });
      },

      getFuelTypes(){
        var self = this;
        self.$axios.get('/fuel_types')
          .then(response => {
            if (!response.data.error){                    
                self.fuel_types = response.data.input_data;
            }
          })
          .catch(error => {
            console.error(error);
          });
      },

      async submit(){
        var self = this;
        const form = this.$refs.filterForm;
        const isValid = await form.validate();

        if (isValid.valid) {
          self.getGraphData();
        }
      },

      getGraphData(){
          var self = this;
          self.loading = true;
	      self.yAxisMin = 0;
	      self.yAxisMax = 400;
          self.$axios.post('/carbon-footprint-graph', self.filter)
          .then(response => {
            if (!response.data.error){ 
                self.chartData = response.data.input_data;
                self.loading = false;
               
                const dataValues = this.chartData.map(item => item.data);
                /*self.yAxisMin = Math.min(...dataValues) - ((2 / 100) * Math.min(...dataValues));
                self.yAxisMax = Math.max(...dataValues) + ((2 / 100) * Math.max(...dataValues));*/
                let yAxisMin = Math.min(...dataValues) - ((2 / 100) * Math.min(...dataValues));
                let yAxisMax = Math.max(...dataValues) + ((2 / 100) * Math.max(...dataValues));
                if (yAxisMin >=400) {
                	self.yAxisMin = yAxisMin;
                	self.yAxisMax = yAxisMax;
                }
                else if (yAxisMin < 1 && (yAxisMax - yAxisMin) < 50) {
                	self.yAxisMin = yAxisMin;
                	self.yAxisMax = yAxisMax + 50;
                }
               
                self.updateChart();
            }
          })
          .catch(error => {
            console.error(error);
          });
      },

      updateChart() {
          const canvasElement = this.$refs.barChart;
          if (canvasElement && canvasElement.getContext) {

            const context = canvasElement.getContext('2d');
            context.clearRect(0, 0, canvasElement.width, canvasElement.height);

            const existingChart = Chart.getChart(canvasElement);
            if (existingChart) {
                existingChart.destroy();
            }

            this.barChartVar = new Chart(context, {
              type: 'line',
              data: {
               labels: this.chartData.map(item => item.label),
                datasets: [
                  {
                    data: this.chartData.map(item => item.data),
                    backgroundColor: this.cyanColor,
                    borderColor: '#2B9AFF',
                    tension: 0.5,
                    pointStyle: 'circle',
                    fill: false,
                    pointRadius: 5,
                    pointHoverRadius: 5,
                    pointHoverBorderWidth: 5,
                    pointBorderColor: 'transparent',
                    pointHoverBorderColor: "#4F5D70",
                    pointHoverBackgroundColor: "#2B9AFF"
                  }
                ]
              },
              options: {
                responsive: true,
                maintainAspectRatio: false,
                animation: {
                  duration: 500
                },
                plugins: {
                  tooltip: {
                    rtl: false,
                    backgroundColor: "#2f3349",
                    titleColor: "#cfd3ec",
                    bodyColor: "#a5a3ae",
                    borderWidth: 1,
                    borderColor: "#dbdade"
                  },
                  legend: {
                    display: false
                  }
                },
                scales: {
                  x: {
                    grid: {
                      color: "#dbdade",
                      drawBorder: false,
                      borderColor: "#dbdade",
                    },
                    ticks: {
                      color: "#a5a3ae",
                    }
                  },
                  y: {
                    min: this.yAxisMin,
                    max: this.yAxisMax,
                    grid: {
                      color:"#dbdade",
                      drawBorder: false,
                      borderColor: "#dbdade",
                    },
                    ticks: {
                      stepSize: (this.yAxisMax - this.yAxisMin) / 5,
                      color: "#a5a3ae",
                      callback: function (value, index, values) {
                        return Math.round(value/ 10) * 10;
                      },
                    },
                    title: {
                        display: true,
                        text: 'Tonnes CO2',
                    },
                  }
                }
              }
            });
          }
      },

      downloadCsv() {

        var self = this;
        const queryParams = Object.keys(self.filter)
              .map(key => {
                  if (key === 'start_date' || key === 'end_date') {
                      return encodeURIComponent(key) + '=' + encodeURIComponent(new Date(self.filter[key]).toISOString());
                  } else {
                      return encodeURIComponent(key) + '=' + encodeURIComponent(self.filter[key]);
                  }
              })
              .join('&');

        const apiUrl = '/download-csv/carbon-footprint?' + queryParams;

        self.$axios.get(apiUrl, { responseType: 'blob' })
        .then(response => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'Carbon_Footprint.csv');
            document.body.appendChild(link);
            link.click();
        })
        .catch(error => {
            console.error('Error downloading CSV', error);
        });
      }
        
    },

    watch:{
        
    },
}
</script>
