<style type="text/css">
    .table th{
        text-transform: none !important;
    }

</style>
<template>
    <div>
        <div class="card">
          <h5 class="card-header">My Reports </h5>
          <div class="table-responsive text-nowrap">
            <table class="table">
                <thead>
                    <tr>
                      <th><strong>Report Name</strong></th>
                      <th><strong>Uploaded Date</strong></th>
                      <th><strong>Uploaded By</strong></th>
                      <th><strong>Actions</strong></th>
                    </tr>
                  </thead>
                  <tbody class="table-border-bottom-0">
                    <tr v-for="report in reports">
                      <td> <span class="fw-medium">{{report.report_name}}</span></td>
                      <td>{{$filters.dateFormat(report.created_at)}}</td>
                      <td>
                            <div class="d-flex justify-content-start align-items-center user-name">
                              <div class="avatar-wrapper"><div class="avatar me-3"><img :src="'images/avatars/'+report.uploaded_by_photo" alt="Avatar" class="rounded-circle"></div></div> 
                              <div class="d-flex flex-column"><a href="javascript::void(0)" class="text-body text-truncate">
                                <span class="fw-medium">{{report.uploaded_by_full_name}}</span></a></div>
                            </div>
                        </td>
                      <td>
                        <div class="d-flex align-items-center">
                            <a :href="'/uploads/'+report.file_name" class="text-body downlaod-record"><i class="ti ti-download ti-sm mx-2"></i></a>
                        </div>
                      </td>
                    </tr>
                    <tr v-if="reports.length==0">
                      <td colspan="4" class="text-center">No Record Found.</td>
                    </tr>
                </tbody>
            </table>
          </div>
        </div>
    </div>
</template>

<script>

export default {
    name: 'reports',
    components:{
    },
    props: [],
    data(){
        return{
           reports: []
        }   
    },

    created() {
        var self =this;
        self.getReports();
    },
    setup() {
   
    },

    computed:{
        
    },

    methods:{
      getReports(){
        var self = this;
        self.$axios.get('/my-reports')
          .then(response => {
            if (!response.data.error){                    
                self.reports = response.data.input_data;
            }
          })
          .catch(error => {
            console.error(error);
          });
      }
        
    },

    watch:{
        
    },
}
</script>
