<template>
   <div class="d-flex">
        <a href="#" class="me-2" v-if="user.logo">
          <img :src="'/images/avatars/'+user.logo" :alt="user.first_name" id="account-upload-img" class="uploadedAvatar rounded me-50" alt="profile image" height="100" width="100" />
        </a>
        <input type="file" @change="uploadPhoto" />
   </div>
</template>
<script>
export default{
    name: "PhotoUpload",
    data(){
    	return {
    	}
    },
    props:{
    	user: {type: Object, default: {}},
    },
    methods:{
        uploadPhoto(event) {
          var self = this;
          const file = event.target.files[0];
          const formData = new FormData();
          formData.append("photo", file);

          self.$axios.post("user/upload-photo", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then(response => {
            self.user.logo = response.data.data;
            self.$emit('update-logo', response.data.data);
          })
          .catch(error => {
            console.error(error);
          });
        },
    }

}
</script>