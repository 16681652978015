<style type="text/css">
/* Absolute Center Spinner */
.loading1 {
  position: fixed;
  z-index: 9999;
  overflow: show;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 50px;
  height: 50px;
}

.custom-loading {
  position: absolute;
  z-index: 9999;
  overflow: show;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 50px;
  height: 50px;
}

/* Transparent Overlay */
.loading1:before {
  content: '';
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255,255,255,0.5);
}
.loading1:not(:required):after {
  content: '';
  display: block;
  font-size: 10px;
  width: 50px;
  height: 50px;
  margin-top: -0.5em;
  border: 3px solid var(--bs-primary);
  border-radius: 100%;
  border-bottom-color: transparent;
  -webkit-animation: rotation 1s linear 0s infinite;
  animation: rotation 1s linear 0s infinite;
}



/* Animation */
@-webkit-keyframes rotation {
   from {-webkit-transform: rotate(0deg);}
   to {-webkit-transform: rotate(359deg);}
}
@-moz-keyframes rotation {
   from {-moz-transform: rotate(0deg);}
   to {-moz-transform: rotate(359deg);}
}
@-o-keyframes rotation {
   from {-o-transform: rotate(0deg);}
   to {-o-transform: rotate(359deg);}
}
@keyframes rotation {
   from {transform: rotate(0deg);}
   to {transform: rotate(359deg);}
}
@keyframes spinner {
  0%   {transform: rotate(0deg);}

  100% {transform: rotate(360deg);}
}

</style>
<template>
    <div  v-if="active && isFullPage && !isCustom" class="loading1"  role="status" :class="elClass"></div>
    
    <div  v-if="active && !isFullPage && !isCustom && !isWhite" :style="'width:'+ size+'px; height:'+size+'px;'" :class="elClass" class="spinner-border text-primary"  role="status"></div>
    <div  v-if="active && !isFullPage && !isCustom && isWhite" :style="'width:'+ size+'px; height:'+size+'px;'" :class="elClass" class="spinner-border text-white"  role="status"></div>

    <!-- spinner for dashboard perticular tile -->
    <div  v-if="active && !isFullPage && isCustom" :style="'width:'+ size+'px; height:'+size+'px;'" :class="elClass" class="spinner-border custom-loading text-primary"  role="status"></div>
</template>
<script>
export default{
    name: "Spinner",
    props:{
        active : {type:Boolean,default:false},
        isFullPage : {type:Boolean,default:false},
        size: {type:Number,default:25},
        elClass:{type: String,default:'is-active'},
        isCustom : {type:Boolean,default:false},
        isWhite : {type:Boolean,default:false},
    },
    /*created(){
      console.log(this.active,this.isFullPage,this.isCustom );
    }*/
}
</script>