<style type="text/css">
    .table th{
        text-transform: none !important;
    }

</style>
<template>
    <div v-if="$store.state.AppConfig.loggedin_user.role_name =='superadmin'">
        <div class="card">
          <h5 class="card-header">Upload Policy</h5>
          <div class="card-body">
            <VeeForm  ref="reportForm" as="div" class="needs-validation" name="policy" v-slot="{ errors }">
              <form class="validate-form mt-2 pt-50" method="POST" action="/">
                <div class="row g-3">
                    <div class="col-md-6">

                       <div class="mb-3">
                          <label class="form-label" for="customer"><strong>Client<span class="required">*</span></strong></label>
                          <select
                            id="customer"
                            name="customer"
                            class="select2 form-select form-control"
                            aria-label="Default select example"
                            v-model="policy.client"
                          >
                            <option value="">--Select--</option>
                            <option v-if="$store.state.AppConfig.customers.length" v-for="customer in $store.state.AppConfig.customers" :value="customer.CustomerID">{{customer.CompanyName}}</option>
                          </select>
                        </div>

                        <div class="mb-3">
                          <label class="form-label" for="type"><strong>Type<span class="required">*</span></strong></label>
                          <select
                            id="type"
                            name="type"
                            class="select2 form-select form-control"
                            aria-label="Default select example"
                            v-model="policy.type"
                          >
                            <option value="">--Select--</option>
                            <option v-if="shared.types" v-for="type in shared.types" :value="type.name">{{type.title}}</option>
                          </select>
                        </div>
                    </div>                   
                </div>

                <div class="row g-3">
                    <div class="col-md-6">
                      <FileUpload :data="policy" url="/api/service/v1/policy/upload"/>
                    </div>                   
                </div>
                
                <div class="pt-4">
                    <button type="button" @click="submitForm" class="btn btn-primary me-sm-3 me-1 waves-effect waves-light">Submit</button>
                    <router-link  class="btn btn-label-primary waves-effect" to="/policies/history">Cancel</router-link>
                  </div>
              </form>
            </VeeForm>
          </div>
        </div>
    </div>
    <div v-else>
      Access denied.
    </div>
</template>

<script>
import jQuery from 'jquery'
import moment from 'moment';
import {Form, Field ,useFieldError,useFormErrors,useValidateForm,useField,useForm} from 'vee-validate';
import vSelect from 'vue-select';
import 'vue-select/dist/vue-select.css';
import '../../js/validation';
import FileUpload from "../../components/FileUpload.vue";

export default {
    name: 'reports',
    components:{
        'VeeForm': Form,
        Field,
        'v-select': vSelect,
        FileUpload
    },
    props: ['shared'],
    data(){
        return{
            policy: {
              client: "",
              type: "",
              file_name: ""
            },
            /*types :  [
              {"name": "policy", "title":"Policy" },
              {"name": "contract", "title":"Contract"},
              {"name": "resource", "title":"Resource"}
            ]*/
        }   
    },

    created() {
    },
    setup() {
    },
    mounted() {
    },

    computed:{
    },

    methods:{

        newPolicy(){
          var self = this;
          self.policy = {
            client: null,
            type: "",
            file_name: ""
          }
        },
      
        async submitForm() {
            const form = this.$refs.reportForm;
            const isValid = await form.validate();

            if (isValid.valid) {
              this.storePolicy();
            }
        },

        storePolicy(){
          var self = this;
          self.$axios.post('/policy', self.policy)
            .then(response => {
              if (!response.data.error){ 
                 self.$router.push({ path: '/policies/history' });
              }
              else{
                toastr['error'](response.data.input_data.error_message, 'Error', {
                      closeButton: true,
                      tapToDismiss: false,
                      timeOut: 5000,
                      rtl: false,
                  });
              }
            })
            .catch(error => {
              console.error(error);
            });
        },

        updateFilename(filename){
          this.policy.file_name = filename;
        }
        
    },

    watch:{
        
    },
}
</script>
