<style>
.hospital-logo {
    object-fit: contain;
    max-height: 70px;
    width: auto;
    max-width: 350px;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
}
</style>
<template>
  <router-view></router-view>
</template>

<script>
import { Options, Vue } from 'vue-class-component';

export default  {
  components:{
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
