<style type="text/css">
    .scrollable-table {
      /*max-height: 300px;*/
      overflow-y: auto;
    }

    th, td {
      white-space: nowrap;
    }

    .text-center{
      text-align: center;
    }

</style>
<template>
    <div class="content-wrapper">
       <div class="container-xxl flex-grow-1 container-p-y">
          <h4 class="fw-bold">tblVehCostAllocation</h4>

          <div class="alert alert-success mb-3" role="alert">
               <span>Invoice Number: {{invoice_number}}</span>
          </div>

          <div class="card mb-4">
            <div class="card-body">
              <div class="table-responsive scrollable-table">
                <table class="table w-100">
                  <thead>
                    <tr>
                      <th><strong>Customer</strong></th>
                      <th><strong>Cost_Category</strong></th>
                      <th><strong>RegNumber</strong></th>
                      <th><strong>EmployeeID</strong></th>
                      <th><strong>Invoice Number</strong></th>
                      <th><strong>VehExpenseDate</strong></th>
                      <th><strong>CostCentre</strong></th>
                      <th><strong>VehExpense Code</strong></th>
                      <th><strong>Currency</strong></th>
                      <th><strong>NetExpenseAmt</strong></th>
                      <th><strong>SupplierCode</strong></th>
                      <th><strong>Fuel Litres</strong></th>
                      <th><strong>Incident_Reference</strong></th>
                      <th><strong>QueryType</strong></th>
                      <th><strong>QueryDate</strong></th>
                      <th><strong>ResolvedDate</strong></th>
                      <th><strong>Comments</strong></th>
                    </tr>
                  </thead>
                  <tbody class="table-border-bottom-0">
                    <tr v-for="data in tableData" v-if="tableData.length>0">
                      <td>{{data.Customer}}</td>
                      <td>{{data.Cost_Category}}</td>
                      <td>{{data.RegNumber}}</td>
                      <td>{{data.EmployeeID}}</td>
                      <td>{{data['Invoice Number']}}</td>
                      <td>{{$filters.dateFormat(data.VehExpenseDate)}}</td>
                      <td>{{data.CostCentre}}</td>
                      <td>{{data["VehExpense Code"]}}</td>
                      <td>{{data.Currency}}</td>
                      <td>{{$filters.numberFormat(data.NetExpenseAmt)}}</td>
                      <td>{{data.SupplierCode}}</td>
                      <td>{{$filters.numberFormat(data['Fuel Litres'])}}</td>
                      <td>{{data.Incident_Reference}}</td>
                      <td>{{data.QueryType}}</td>
                      <td>{{$filters.dateFormat(data.QueryDate)}}</td>
                      <td>{{$filters.dateFormat(data.ResolvedDate)}}</td>
                      <td>{{data.Comments}}</td>
                    </tr>
                    <tr v-else>
                        <td colspan="8" class="text-center">No record found.</td>
                    </tr>
                  </tbody>
                </table>
                <spinner :active="loading" :is-full-page="true"></spinner> 
              </div>
            </div>
          </div>
        </div>
    </div>
</template>

<script>

export default {
    name: 'tblVehCostAllocation',
    components:{
    },
    props: [],
    data(){
        return{
           tableData: [],
           invoice_number: this.$route.params.invoice_number,
           loading: false
        }   
    },
    created() {
      var self = this;
      self.gettblVehCostAllocationData();
    },
    mounted() {
    },
    setup() {
    },
    computed:{
        
    },

    methods:{
        gettblVehCostAllocationData(){
          var self = this;
          self.loading = true;
          self.$axios.get('/tblVehCostAllocation/'+self.invoice_number)
          .then(response => {
            if (!response.data.error){ 
                self.tableData = response.data.input_data;
                self.loading = false;
            }
          })
          .catch(error => {
            console.error(error);
          });
      },
    },

    watch:{
        
    },
}
</script>
