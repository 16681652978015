// /////////////////////////////////////////////
// Variables
// /////////////////////////////////////////////

const userDefaults = {
  uid         : 0,          // From Auth
  displayName : "", // From Auth
  about       : "",
  photoURL    : '', // From Auth
  status      : "online",
  userRole    : "",
  languages   : [],
  headers     : "",
  timeFormat  : "",
  logo        : "",
  basePath    : "",
  timezone    : "",
  dateFormat  : "MMM-DD-YYYY",
  androidPatientAppLink: '',
  iosPatientAppLink: '',
  email: null,
  powered_by_logo:null,
  powered_by_text:null,
  age_limit_of_birth: 18,
}

const AppConfigDefaults = 
{
  loggedin_user: {
  },
  loggedin_user_client: {
  },
  client_prefix: {
  },
  customers: [],
  countries:[],
  suppliers:[],
  AppConfig: {
    "age_limit_of_birth": "18",
  },
  TimeZone: "UTC",
  BrandingConfigs: {
  },
  MasterSettings: {
  },
  version_txt: "",
  PortalDateFormat: "MMM-DD-YYYY",
  CommonVariables: {
  },
  AppointmentConfig : {
    "available_slots_in_calendar" : null
  },
  ActiveSubscription:{},
  sideBarPermissions:{
    hasAccessToAppointment: false,
    hasAccessToAppointments: false,
    hasAccessToAvailabilities: false,
    hasAccessToBilling: false,
    hasAccessToConfig: false,
    hasAccessToDashboard: false,
    hasAccessToDownloadHistory: false,
    hasAccessToEncounters: false,
    hasAccessToMasterDailyPlan: false,
    hasAccessToMasterSourceSegment: false,
    hasAccessToOtherExports: false,
    hasAccessToProviders: false,
    hasAccessToResources: false,
    hasAccessToRolesPermissions: false,
    hasAccessToSystem_message: false,
    hasAccessToTemplateDailyPlan: false,
    hasAccessUserModel: false,
    hasPermissionProviders: false,
  },
  costCSVDownloading: false,
  fleetSizeCSVDownloading: false,
  vehicleOrderCSVDownloading: false,
  co2CSVDownloading: false,
  historyCSVDownloading: false,
  invoiceCSVDownloading: false
}
// /////////////////////////////////////////////
// State
// /////////////////////////////////////////////

const state = {
    AppActiveUser           : userDefaults,
    AppConfig               : AppConfigDefaults,
}
export default state
