<style type="text/css">
    

</style>
<template>
    <div >
        <div class="content-wrapper">
            <div class="container-xxl flex-grow-1 container-p-y">
              <h4 class="fw-bold ">Dashboard</h4>
              <div class="row" v-if="$store.state.AppConfig.loggedin_user.role_name !='superadmin'">
                <!-- <pre>{{data}}</pre> -->
                <div class="col-12 col-xl-3 mb-4 col-md-6">
                  <div class="card">
                    
                    <div class="card-header d-flex justify-content-between">
                      <router-link to="/report/fleet-size"><h5 class="mb-0 card-title">Fleet Size</h5></router-link>
                      <div class="dropdown">
                        <button class="btn p-0" type="button" id="projectStatusId" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                          <i class="ti ti-dots-vertical ti-sm text-muted"></i>
                        </button>
                        <div class="dropdown-menu dropdown-menu-end" aria-labelledby="projectStatusId" style="">
                          <router-link  class="dropdown-item" to="/report/fleet-size">View More</router-link>
                        </div>
                      </div>
                    </div>
                    <div class="card-body">
                      <div class="d-flex align-items-start">
                        <div class="badge rounded bg-label-info p-2 me-3 rounded">
                          <i class="ti ti-chart-bar ti-sm"></i>
                        </div>
                        <div class="d-flex justify-content-between w-100 gap-2 align-items-center">
                          <div class="me-2">
                            <h6 class="mb-0">{{data.prevYearFleet}}</h6>
                            <small class="text-muted">Last 12 Months</small>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- <div class="col-12 col-xl-3 mb-4 col-md-6">
                  <div class="card">
                    <div class="card-header d-flex justify-content-between">
                      <h5 class="mb-0 card-title">Replacements Due</h5>
                      <div class="dropdown">
                        <button class="btn p-0" type="button" id="projectStatusId" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                          <i class="ti ti-dots-vertical ti-sm text-muted"></i>
                        </button>
                        <div class="dropdown-menu dropdown-menu-end" aria-labelledby="projectStatusId" style="">
                          <router-link  class="dropdown-item" to="/report/replacements-due">View More</router-link>
                        </div>
                      </div>
                    </div>
                    <div class="card-body">
                      <div class="d-flex align-items-start">
                        <div class="badge rounded bg-label-info p-2 me-3 rounded">
                          <i class="ti ti-chart-bar ti-sm"></i>
                        </div>
                        <div class="d-flex justify-content-between w-100 gap-2 align-items-center">
                          <div class="me-2">
                            <h6 class="mb-0">{{data.nextYearReplacement}}</h6>
                            <small class="text-muted">Coming 12 months</small>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div> -->

                <div class="col-12 col-xl-3 mb-4 col-md-6">
                  <div class="card">
                    <div class="card-header d-flex justify-content-between">
                      <router-link to="/report/vehicle-on-order"><h5 class="mb-0 card-title">Current Vehicles on Order</h5></router-link>
                      <div class="dropdown">
                        <button class="btn p-0" type="button" id="projectStatusId" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                          <i class="ti ti-dots-vertical ti-sm text-muted"></i>
                        </button>
                        <div class="dropdown-menu dropdown-menu-end" aria-labelledby="projectStatusId" style="">
                          <router-link  class="dropdown-item" to="/report/vehicle-on-order">View More</router-link>
                        </div>
                      </div>
                    </div>
                    <div class="card-body">
                      <div class="d-flex align-items-start">
                        <div class="badge rounded bg-label-info p-2 me-3 rounded">
                          <i class="ti ti-chart-bar ti-sm"></i>
                        </div>
                        <div class="d-flex justify-content-between w-100 gap-2 align-items-center">
                          <div class="me-2">
                            <h6 class="mb-0">{{data.prevYearOrder}}</h6>
                            <small class="text-muted">Last 12 months</small>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-12 col-xl-3 mb-4 col-md-6">
                  <div class="card">
                    <div class="card-header d-flex justify-content-between">
                      <router-link to="/report/co2"><h5 class="mb-0 card-title">CO2</h5></router-link>
                      <div class="dropdown">
                        <button class="btn p-0" type="button" id="projectStatusId" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                          <i class="ti ti-dots-vertical ti-sm text-muted"></i>
                        </button>
                        <div class="dropdown-menu dropdown-menu-end" aria-labelledby="projectStatusId" style="">
                          <router-link  class="dropdown-item" to="/report/co2">View More</router-link>
                        </div>
                      </div>
                    </div>
                    <div class="card-body">
                      <div class="d-flex align-items-start">
                        <div class="badge rounded bg-label-info p-2 me-3 rounded">
                          <i class="ti ti-chart-bar ti-sm"></i>
                        </div>
                        <div class="d-flex justify-content-between w-100 gap-2 align-items-center">
                          <div class="me-2">
                            <h6 class="mb-0">{{data.prevYearAvgCO2}}</h6>
                            <small class="text-muted">Last 12 months</small>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-12 col-xl-3 mb-4 col-md-6">
                  <div class="card">
                    <div class="card-header d-flex justify-content-between">
                      <router-link to="/report/carbon-footprint"><h5 class="mb-0 card-title">Carbon Footprint</h5></router-link>
                      <div class="dropdown">
                        <button class="btn p-0" type="button" id="projectStatusId" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                          <i class="ti ti-dots-vertical ti-sm text-muted"></i>
                        </button>
                        <div class="dropdown-menu dropdown-menu-end" aria-labelledby="projectStatusId" style="">
                          <router-link  class="dropdown-item" to="/report/carbon-footprint">View More</router-link>
                        </div>
                      </div>
                    </div>
                    <div class="card-body">
                      <div class="d-flex align-items-start">
                        <div class="badge rounded bg-label-info p-2 me-3 rounded">
                          <i class="ti ti-chart-bar ti-sm"></i>
                        </div>
                        <div class="d-flex justify-content-between w-100 gap-2 align-items-center">
                          <div class="me-2">
                            <h6 class="mb-0">{{data.prevYearCarbonFootprint}} Tonnes</h6>
                            <small class="text-muted">Last 12 months</small>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-12 col-xl-3 mb-4 col-md-6">
                  <div class="card">
                    <div class="card-header d-flex justify-content-between">
                      <router-link to="/report/costs"><h5 class="mb-0 card-title">Costs</h5></router-link>
                      <div class="dropdown">
                        <button class="btn p-0" type="button" id="projectStatusId" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                          <i class="ti ti-dots-vertical ti-sm text-muted"></i>
                        </button>
                        <div class="dropdown-menu dropdown-menu-end" aria-labelledby="projectStatusId" style="">
                          <router-link  class="dropdown-item" to="/report/costs">View More</router-link>
                        </div>
                      </div>
                    </div>
                    <div class="card-body">
                      <div class="d-flex align-items-start">
                        <div class="badge rounded bg-label-danger p-2 me-3 rounded">
                          <i class="ti ti-currency ti-sm"></i>
                        </div>
                        <div class="d-flex justify-content-between w-100 gap-2 align-items-center">
                          <div class="me-2">
                            <h6 class="mb-0">€{{data.prevYearCost}}</h6>
                            <small class="text-muted">Last 12 months</small>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- <div class="col-12 col-xl-3 mb-4 col-md-6">
                  <div class="card">
                    <div class="card-header d-flex justify-content-between">
                      <h5 class="mb-0 card-title">Vehicle or Driver</h5>
                    </div>
                    <div class="card-body">
                      <div class="d-flex align-items-start">
                        <div class="badge rounded bg-label-warning p-2 me-3 rounded">
                          <i class="ti ti-search ti-sm"></i>
                        </div>
                        <div class="d-flex justify-content-between w-100 gap-2 align-items-center">
                          <div class="me-2">
                            <small class="text-muted">Find Vehicle or Driver</small>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div> -->

                <div class="col-12 col-xl-3 mb-4 col-md-6">
                  <div class="card">
                    <div class="card-header d-flex justify-content-between">
                      <router-link to="/report/vehicle-employee-history"><h5 class="mb-0 card-title">Vehicle or Driver</h5></router-link>
                      <div class="dropdown">
                        <button class="btn p-0" type="button" id="projectStatusId" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                          <i class="ti ti-dots-vertical ti-sm text-muted"></i>
                        </button>
                        <div class="dropdown-menu dropdown-menu-end" aria-labelledby="projectStatusId" style="">
                          <router-link  class="dropdown-item" to="/report/vehicle-employee-history">View More</router-link>
                        </div>
                      </div>
                    </div>
                    <div class="card-body">
                      <div class="d-flex align-items-start">
                        <div class="badge rounded bg-label-warning p-2 me-3 rounded">
                          <i class="ti ti-search ti-sm"></i>
                        </div>
                        <div class="d-flex justify-content-between w-100 gap-2 align-items-center">
                          <div class="me-2">
                            <small class="text-muted">Find Vehicle or Driver</small>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>


                <div class="col-12 col-xl-3 mb-4 col-md-6">
                  <div class="card">
                    <div class="card-header d-flex justify-content-between">
                      <router-link to="/report/invoice"><h5 class="mb-0 card-title">Invoice</h5></router-link>
                      <div class="dropdown">
                        <button class="btn p-0" type="button" id="projectStatusId" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                          <i class="ti ti-dots-vertical ti-sm text-muted"></i>
                        </button>
                        <div class="dropdown-menu dropdown-menu-end" aria-labelledby="projectStatusId" style="">
                          <router-link  class="dropdown-item" to="/report/invoice">View More</router-link>
                        </div>
                      </div>
                    </div>
                    <div class="card-body">
                      <div class="d-flex align-items-start">
                        <div class="badge rounded bg-label-warning p-2 me-3 rounded">
                          <i class="ti ti-search ti-sm"></i>
                        </div>
                        <div class="d-flex justify-content-between w-100 gap-2 align-items-center">
                          <div class="me-2">
                            <small class="text-muted">Find an Invoice</small>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- <div class="col-12 col-xl-3 mb-4 col-md-6">
                  <div class="card">
                    <div class="card-header d-flex justify-content-between">
                      <h5 class="mb-0 card-title">Invoice</h5>
                    </div>
                    <div class="card-body">
                      <div class="d-flex align-items-start">
                        <div class="badge rounded bg-label-warning p-2 me-3 rounded">
                          <i class="ti ti-search ti-sm"></i>
                        </div>
                        <div class="d-flex justify-content-between w-100 gap-2 align-items-center">
                          <div class="me-2">
                            <small class="text-muted">Find an Invoice</small>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div> -->

                <div class="col-xl-3 col-md-4 col-6 mb-4">
                  <div class="card">
                    <div class="card-body d-flex justify-content-between align-items-center">
                      <div class="card-title mb-0">
                        <router-link to="/my-reports"><h5 class="mb-0 me-2">My Reports</h5></router-link>
                       <!--  <small>Reports uploaded by super admin</small> -->
                      </div>
                      <div class="card-icon">
                        <span class="badge bg-label-success rounded-pill p-2">
                          <i class="ti ti-files ti-sm"></i>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-xl-3 col-md-4 col-6 mb-4">
                  <div class="card">
                    <div class="card-body d-flex justify-content-between align-items-center">
                      <div class="card-title mb-0">
                        <!-- <h5 class="mb-0 me-2">Policies Contracts</h5> -->
                        <router-link to="/policies"><h5 class="mb-0 me-2 card-title">Policies Contracts</h5></router-link>
                      </div>
                      <div class="card-icon">
                        <span class="badge bg-label-success rounded-pill p-2">
                          <i class="ti ti-server ti-sm"></i>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row" v-else>
                  <div class="card">
                       <div class="card-body">
                          <div class="col-12 col-xl-3 mb-4 col-md-6">
                            Super admin dashboard
                          </div>
                        </div>
                  </div>
              </div>
            </div>

            <spinner :active="loading" :is-full-page="true"></spinner> 
        </div>
    </div>
</template>

<script>

export default {
    name: 'dashboard',
    components:{
    },
    props: [],
    data(){
        return{
           data: [],
           loading: false 
        }   
    },

    created() {
      var self = this;
      self.getDashboardData();
    },
    setup() {
    },

    computed:{
        
    },

    methods:{
        getDashboardData(){
            var self = this;
            self.loading = true;
            self.$axios.get('/dashboard')
              .then(response => {
                if (!response.data.error){                    
                    self.data = response.data.input_data;
                    self.loading = false;
                }
              })
              .catch(error => {
                console.error(error);
              });
        },
        
    },

    watch:{
        
    },
}
</script>
